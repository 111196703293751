import React from 'react';
import {settings} from '../../common/constants';
import models from "../../store/models";
import {withRouter} from "react-router";
import {connect} from "react-redux";

class PageSize extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page_setup: {
                dimensions: {
                    width: 70,
                    height: 200,
                },
                margins: {
                    top: 1,
                    right: 1,
                    bottom: 1,
                    left: 1
                }
            }
        }
    }

    componentDidMount() {
        this.setForm(this.props);
    }

    setForm(props) {
        if (props.settings.success === true && props.settings.list.length > 0) {
            console.log('setForm settings', props.settings);
            const settings = props.settings.list[0];
            const {page_setup} = settings;
            this.setState({page_setup: page_setup[this.props.id]});
        } else {
            console.log('setForm default', props.default);
            const {page_setup} = props.default;
            this.setState({page_setup: page_setup[this.props.id]});
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setForm(nextProps);
    }

    onChange = (e, value) => {
        e.preventDefault();
        const [key, subkey] = e.target.id.split('.');
        const {page_setup} = this.state;
        page_setup[key] = {...page_setup[key], ...{[subkey]: value}};
        this.setState({page_setup});
        this.props.setPageSetup(this.props.id, page_setup);
    };

    render() {
        const {page_setup} = this.state;
        return (
            <div className="row my-2">
                <div className="col-md-6">
                    <label htmlFor="dimensions.width">Width <span className="small">(mm)</span></label>
                    <input id="dimensions.width" value={page_setup.dimensions.width}
                           onChange={(e) => this.onChange(e, e.target.value)}
                           type="text" className="form-control" placeholder="Skriv width"/>
                </div>
                <div className="col-md-6">
                    <label htmlFor="dimensions.height">Height <span className="small">(mm)</span></label>
                    <input id="dimensions.height" value={page_setup.dimensions.height}
                           onChange={(e) => this.onChange(e, e.target.value)}
                           type="text" className="form-control" placeholder="Skriv height"/>
                </div>

                <label htmlFor="height">Margins <span className="small">(mm)</span></label>
                <div className="input-group">
                    <input id="margins.top" value={page_setup.margins.top}
                           onChange={(e) => this.onChange(e, e.target.value)}
                           className="form-control" type="text" placeholder="top"/>
                    <input id="margins.right" value={page_setup.margins.right}
                           onChange={(e) => this.onChange(e, e.target.value)}
                           className="form-control" type="text" placeholder="right"/>
                    <input id="margins.bottom" value={page_setup.margins.bottom}
                           onChange={(e) => this.onChange(e, e.target.value)}
                           className="form-control" type="text" placeholder="bottom"/>
                    <input id="margins.left" value={page_setup.margins.left}
                           onChange={(e) => this.onChange(e, e.target.value)}
                           className="form-control" type="text" placeholder="left"/>
                </div>
            </div>
        )
    }
}

PageSize.defaultProps = {
    default: {
        page_setup: settings.page_setup
    }
};

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapActionsToProps = {};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(PageSize));
