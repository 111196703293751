import React from "react";
import {withRouter} from "react-router-dom";
import models from "../../store/models";
import {connect} from "react-redux";
import Table from './Table';
import {padStart} from "lodash";

const stringSimilarity = require('string-similarity');
const ROWS_LIMIT = 30; 

class OrderList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: {
                list: [],
                errors: false,
            },
            search: {
                service_number: '',
                name: '',
                telephone: '',
            },
            searchedList: []
        }
    }

    componentDidMount() {
        console.log("componentDidMount OrderList", this.props);
        // this.props.readAction({});
        this.setLists(this.props);
        this.setForm(this.props);
    }

    setForm = (props) => {
        const {form} = props.searches;
        if (form.service_number && isNaN(form.service_number)) {
            form.service_number = form.service_number.substr(1);
        } else {
            form.service_number = this.padServiceNumber(form.service_number);
        }
        this.setState({search: {...form}});
        // Show modal
        // document.getElementById('show-order-list').click();
        this.btnRef.click();
        // Start search
        setTimeout(() => {
            console.log('Start search with', form, this.state.order.list.length)
            this.startSearch(form);
        }, 100);
    };

    setLists = (props) => {
        const {orders} = props;
        if (orders && orders.success && orders.list && orders.list.length > 0) {
            const {order} = this.state;
            order.list = orders.list;
            this.setState({order});
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        console.log("componentWillReceiveProps OrderList");
        console.log('componentWillReceiveProps orderbox render', nextProps)
        this.setLists(nextProps);
        this.setForm(nextProps);
    }

    onChange = (e) => {
        e.preventDefault();
        const {search} = this.state;
        // Put padding for service_number
        if (e.target.id === 'service_number') {
            search[e.target.id] = this.padServiceNumber(e.target.value);
        } else {
            search[e.target.id] = e.target.value;
        }
        this.setState({search});
        this.startSearch(search);
    };

    padServiceNumber = (service_number) => {
        const LENGTH = 6;
        // Put padding for service_number
        if (service_number !== '') {
            return padStart(Number(service_number), LENGTH, '0');
        }
        return service_number;
    };

    onFocus = e => {
        e.preventDefault();
        e.target.select();
    };

    startSearch = (search) => {
        let searchedList = this.state.order.list;
        if (search.service_number.length > 0) {
            searchedList = this.searchBy('service_number')
        } else if (search.name.length > 1) {
            searchedList = this.searchBy('name')
        } else if (search.telephone.length > 1) {
            searchedList = this.searchBy('telephone')
        }
        this.setState({searchedList});
        console.log('searchedList', searchedList)
    };

    searchBy = attribute => {
        let {order, search} = this.state;
        let list = order.list.map(item => {
            if(item[attribute] && search[attribute]) {
                item.similarity = stringSimilarity.compareTwoStrings(item[attribute], search[attribute]);
            } else {
                item.similarity = 1;
            }
            return item;
        });
        list = list.sort((a, b) => b.similarity - a.similarity);
        return list;
    };

    render() {
        const {search} = this.state;
        return (
            <div className="container mt-3">
                {/* <!-- 2-4-1-3-2 -->*/}
                <div className="row">
                    <div className="col-md-1 text-right"><label htmlFor="service_number" className="col-form-label">Service
                        Nr </label>
                    </div>
                    <div className="col-md-3">

                        <div className="input-group">
                            <span className="input-group-text">A</span>
                            <input
                                type="text"
                                className="form-control"
                                id="service_number"
                                placeholder="Enter service number"
                                value={search.service_number}
                                onChange={this.onChange}
                                onFocus={this.onFocus}
                                onMouseEnter={this.onFocus}
                                autoComplete="off"
                                required
                            />
                            <div className="invalid-feedback">
                                Your username is required.
                            </div>
                        </div>

                    </div>
                    <div className="col-md-1 text-right">
                        <label htmlFor="date" className="col-form-label">Namn</label>
                    </div>
                    <div className="col-md-3">
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Skriv namn"
                            value={search.name}
                            onChange={this.onChange}
                            onFocus={this.onFocus}
                            required
                        />
                    </div>
                    <div className="col-md-1 text-right">
                        <label htmlFor="date" className="col-form-label">Telefon</label>
                    </div>
                    <div className="col-md-3">
                        <input
                            type="text"
                            className="form-control"
                            id="telephone"
                            placeholder="Skriv telefon"
                            value={search.telephone}
                            onChange={this.onChange}
                            onFocus={this.onFocus}
                            required
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 table-responsive" style={{overflow: 'auto', maxHeight: 'calc(100vh - 300px)'}}>
                        <Table push={this.props.history.push} table={{
                            cols: ['Service Nr', 'Telfon Nu', 'Namn', 'Enhet', 'Fel', 'Datum', 'Tid', 'Status'],
                            fields: ['service_number', 'telephone', 'name', 'name', 'services', 'date', 'time', 'status'],
                            rows: this.state.searchedList.slice(0, ROWS_LIMIT)
                        }}/>
                    </div>
                    <button ref={ref => this.btnRef = ref} style={{display: 'none'}} id="show-order-list" type="button"
                            className="btn btn-warning ml-2" data-toggle="modal"
                            data-target="#featuretteModal"
                            data-whatever="@mdo">
                    </button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    orders: state.orders,
    searches: state.searches,
});

const mapActionsToProps = {
    readAction: models.orders.actions.read,
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(OrderList));

