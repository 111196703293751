import React from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";

function PrivateRoute(ComposedComponent) {
    class Hoc extends React.Component {

        render() {
            const {logins} = this.props;
            !(logins && logins.form && logins.form[0] && logins.form[0].email) && this.props.history.push('/');

            return (
                <ComposedComponent/>
            )
        }
    }

    const mapActionsToProps = {};

    const mapStateToProps = state => ({
        logins: state.logins
    });
    return withRouter(connect(mapStateToProps, mapActionsToProps)(Hoc));
}

export default PrivateRoute;


