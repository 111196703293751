import React from "react";

class Fix extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { model } = this.props;

    return (
      <div className="print-opinion">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="border-info p-5">
              <div className="opinion">
                <div className="header">
                  <div className="row">
                    <div className="col">
                      <img
                        className="logo-hd"
                        src="/images/logo-colored.jpeg"
                        alt="logo"
                      />
                    </div>
                    <div className="col text-right">
                      <h2>{model.form.deal_title}</h2>
                      <p>{new Date().format()}</p>
                    </div>
                  </div>
                </div>
                <div className="body p-4">
                  <div className="row">
                    <div className="col-4">
                      <strong>Service nr</strong>
                    </div>
                    <div className="col-8 text-left">
                      <p>{model.form.service_number}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <strong>Namn</strong>
                    </div>
                    <div className="col-8 text-left">
                      <p>{model.form.name}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <strong>Telefon nr</strong>
                    </div>
                    <div className="col-8 text-left">
                      <p>{model.form.telephone}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <strong>Enhet</strong>
                    </div>
                    <div className="col-8 text-left">
                      <p>{model.form.product.name}</p>
                    </div>
                  </div>
                  {model.form.imei && (
                    <div className="row">
                      <div className="col-4">
                        <strong>IMEI</strong>
                      </div>
                      <div className="col-8 text-left">
                        <p>{model.form.imei}</p>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-12 mt-5">
                      <table className="table table-bordered fix-table">
                        <thead>
                          <tr>
                            <th>Varor</th>
                            <th>Pris</th>
                          </tr>
                        </thead>
                        <tbody>
                          {model.form.fix?.list.map((fix, i) => (
                            <tr key={i}>
                              <td>{fix.name}</td>
                              <td>{fix.price} kr</td>
                            </tr>
                          ))}
                          {model.form.screen_cover ? (
                            <tr>
                              <td>Screen cover</td>
                              <td>100 kr</td>
                            </tr>
                          ) : null}
                          {model.form.express ? (
                            <tr>
                              <td>Express</td>
                              <td>100 kr</td>
                            </tr>
                          ) : null}
                          <tr>
                            <td className="text-right">Totalt</td>
                            <td>{model.form.price} kr</td>
                          </tr>
                          <tr>
                            <td className="text-right">Rabatt</td>
                            <td>{model.discount_amount} kr</td>
                          </tr>
                          <tr>
                            <td className="text-right">Summa</td>
                            <td>{model.form.discountedPrice} kr</td>
                          </tr>
                          <tr>
                            <td className="text-right">Moms(25%)</td>
                            <td>
                              {(0.2 * model.form.discountedPrice).toFixed(2)} kr
                            </td>
                          </tr>
                          <tr>
                            <td className="text-right">
                              <strong>Att betala</strong>
                            </td>
                            <td>{model.form.discountedPrice} kr</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  style={{ backgroundColor: "white", color: "black" }}
                  className="footer px-4"
                >
                  <div className="row">
                    <div className="col mt-5">
                      <h3>Mottagarens Underskrift</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-right">
                      <p>
                        <strong>Tel:</strong> {model.settings.token?.telephone}
                      </p>
                      <p>
                        <strong>Epost:</strong> {model.settings.token?.email}
                      </p>
                      <p>
                        <strong>Org Nr:</strong>{" "}
                        {model.settings.token?.organization_number}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    );
  }
}

export default Fix;
