import React from "react";
import Icons from "../../common/icons";

// props : { list, collection, action, icon_type }
const ListGroup = (props) => (
  <ul
    className="list-group list-group-flush"
    style={{
      height: "216px",
      overflow: "auto",
      border: "1px solid lightgrey",
      padding: "5px",
    }}
  >
    <div style={{ display: "flex", borderBottom: "1px solid lightgrey" }}>
      <span style={{ flex: 4 }}>Namn</span>
      <span style={{ flex: 2 }}> Pris </span>
      <span style={{ flex: 1 }}>Qty</span>
      <span style={{ flex: 1 }}></span>
    </div>

    {props.list &&
      props.list.map((item, i) => (
        <a
          tabIndex="-1"
          style={{ display: "flex" }}
          onClick={(e) => {
            e.preventDefault();
            props.action(item);
          }}
          key={i}
          href="#"
          className="list-group-item list-group-item-action list-group-item-light"
        >
          <span style={{ flex: 4 }}>
            {item.name}
            {item.product ? " - " + item.product.name : ""}
          </span>
          <span style={{ flex: 2 }}> &nbsp;{"  " + item.price}</span>
          <span style={{ flex: 1 }}>{item.qty ? item.qty : "0"}</span>
          <button
            tabIndex="-1"
            style={{ flex: 1, textAlign: "right" }}
            onClick={(e) => {
              e.preventDefault();
              props.action(item);
            }}
            className="p-0 btn btn-outline-light"
          >
            <Icons icon_type={props.icon_type} />
          </button>
        </a>
      ))}
  </ul>
);
export default ListGroup;
