import React from 'react';
import Full from "../../containers/Full";


class Ni extends React.Component {

    render() {
        return (
            <Full>
                <div>Not Implemented component</div>
            </Full>
        )
    }
}

export default Ni;
