import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Dashboard from "../../containers/Dashboard";
import models from "../../store/models";
import Alert from "../Alert";
import SearchBox from "../search/SearchBox";
import ListGroup from "./ListGroup";
import CheckButton from "../CheckButton";
import Order from "../order/Order";
import Make from "./Make";
import Product from "./Product";
import Service from "./Service";
import Select from "react-select";
import Toast from "react-bootstrap/Toast";
import Modal from "../print/Modal";
import Opinion from "../print/Opinion";
import Fix from "../print/Fix";
import Shop from "../print/Shop";
import Sell from "../print/Sell";
import PrintButton from "./PrintButton";
import { settings } from "../../common/constants";
import Dialog from "../Dialog";

const ERROR_TIME = 5000;

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: new Order(),
      make: new Make(),
      product: new Product(),
      service: new Service(),
      extras: [],
      times: [],
      show: true,
      showDealEditDialog: false,
      name: "",
      telephone: "",
    };
  }

  toggleShow = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  // CRUD
  create = () => {
    const { model } = this.state;
    if (model.isNewRecord || !(model._id || model.id)) {
      model.isNewRecord = true;
      const order = model.create(this.state.make, this.state.product);
      this.props.createAction({ order });
      this.props.addFlashMessageAction({
        type: "alert-info",
        message: "Saving...",
      });
    } else {
      model.isNewRecord = false;
      const order = model.create(this.state.make, this.state.product);
      this.props.updateAction({ order, id: order.id });
    }
    const { name, telephone } = model.form;
    this.setState({ name, telephone });
  };

  sammaKund = (e) => {
    e.preventDefault();
    const { model, name, telephone } = this.state;
    if (name && telephone) {
      model.form.name = name;
      model.form.telephone = telephone;
      this.setState({ model });
    }
  };

  updateState = (props) => {
    const { makes, products, services, orders, settings, create } = props;
    let { model, make, product, service, extras } = this.state;
    model.setList(orders.list);
    model.setSettings(settings);
    if (
      create &&
      create.data &&
      create.data.data &&
      create.data.data[0] &&
      create.data.data[0]["_id"]
    ) {
      model.form._id = create?.data?.data[0]["_id"];
      console.log("In update state", create, model.id, model);
    } else {
      model.id = "N/A";
      console.log("In update state", create, props);
    }
    make.setList(makes.list);
    product.setList(products.list);
    service.setList(services.list);
    extras = props.extras.list;
    // this.forceUpdate();
    this.setState({ extras });
  };

  componentDidMount() {
    console.log("FORM - componentDidMount");
    this.updateState(this.props);
    if (this.props.match.params.id) {
      this.loadModel(this.props.match.params.id);
    }
  }

  loadModel = (id) => {
    const { model, make, product, service } = this.state;
    if (model.loaded === false) {
      model.load(id);
      make.load(model);
      product.load(model);
      service.filter = model.form?.product;
      this.setState({ model });
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    console.log("FORM - componentWillReceiveProps");
    console.log(nextProps);
    // If create is successful read orders
    if (nextProps.orders.actions === "create_success") {
      this.props.readActionOrders({});
    }
    this.updateState(nextProps);
    if (nextProps.match.params.id) {
      this.loadModel(nextProps.match.params.id);
    }
    // Show Alert at : create, update - for model/orders
    this.showAlert(nextProps);
  }

  showAlert = (props) => {
    const { orders } = props;
    const { model } = this.state;
    switch (orders.actions) {
      case "create_success":
        model.errors = {
          type: "alert-success",
          header: "Success",
          errors: [{ Saved: { message: "Saved successfully !" } }],
          done: true,
        };
        setTimeout(() => {
          model.errors = null;
          model.isNewRecord = false;
          model.form.id = orders.form[0].id;
          this.forceUpdate();
        }, ERROR_TIME);
        break;
      case "create_fail":
        model.errors = {
          type: "alert-danger",
          header: "Errors",
          errors: orders.form,
        };
        setTimeout(() => {
          model.errors = null;
          this.forceUpdate();
        }, ERROR_TIME);
        break;
      case "update_success":
        model.errors = {
          type: "alert-success",
          header: "Success",
          errors: [{ Updated: { message: "Updated successfully !" } }],
          done: true,
        };
        setTimeout(() => {
          model.errors = null;
          model.isNewRecord = false;
          this.forceUpdate();
        }, ERROR_TIME);
        break;
    }
  };

  onMouseEnter = (e) => {
    e.preventDefault();
    e.target.select();
  };

  onChange = (e) => {
    e.preventDefault();
    const { model } = this.state;
    switch (e.target.id) {
      case "discount":
        console.log("discount", e.target.value);
        model.form[e.target.id] = e.target.value;
        model.calculateDiscountedPrice();
        break;
      case "discountedPrice":
        model.form[e.target.id] = e.target.value;
        model.calculateDiscount(Number(e.target.value));
        break;
      default:
        model.form[e.target.id] = e.target.value;
    }
    this.setState({ model });
  };

  onChangeSelect = (selectedOption, collection) => {
    const { model, make, product, service } = this.state;
    switch (collection) {
      case "make":
        make.selectedOption = selectedOption;
        product.filter = make;
        service.filter = product;
        this.setState({ make, product, service });
        break;
      case "product":
        product.selectedOption = selectedOption;
        service.filter = product;
        model.product = selectedOption;
        this.setState({ product, service });
        break;
      case "payment_method":
        model.form.payment_method = selectedOption.value;
        this.setState({ model });
        break;
      case "status":
        console.log(selectedOption);
        model.form.status = Array.isArray(selectedOption.value)
          ? selectedOption.value[0]
          : selectedOption.value;
        this.setState({ model });
        break;
    }
  };
  toggleCheckBox = (field) => {
    const { model } = this.state;
    model.toggleCheckBox(field);
    this.setState({ model });
  };
  toggleDeal = (newDeal) => {
    const { model } = this.state;
    model.deal = newDeal;
    this.setState({ model });
  };

  addNew = () => {
    const { model, make, product, service } = this.state;
    model.addNew();
    make.clear();
    product.clear();
    service.clear();
    this.forceUpdate();
    // this.props.history.push('/receipt');
  };

  removeFromService = (item) => {
    const { model, service } = this.state;
    // service._filter = service._filter.filter(
    //   (service) => service._id !== item._id
    // );
    if (item.qty && item.qty > 0) {
      item.qty = item.qty - 1;
      item.product = { ...model.form.product };
      model.addFix(item);
      this.setState({ model, service });
    } else {
      item.qty = 0;
      alert("Quantity is 0");
    }
  };

  removeFromFix = (item) => {
    const { model, service } = this.state;
    model.removeFix(item);
    // Check if item already exists which is more likely, just find index
    const foundIndex = service._filter.findIndex(
      (element) => element._id == item._id
    );
    if (foundIndex > -1) {
      console.log("foundIndex:", foundIndex);
      const foundItem = service._filter[foundIndex];
      foundItem.qty = foundItem.qty + 1;
      service._filter[foundIndex] = { ...foundItem };
    } else {
      const newItem = { ...item };
      newItem.qty = 1;
      service._filter.push(newItem);
    }
    this.setState({ model, service });
  };

  changeDealText = () => {
    // show dialog
    const { showDealEditDialog } = this.state;
    console.log("showDealEditDialog", showDealEditDialog);
    this.setState({ showDealEditDialog: !showDealEditDialog });
  };

  clearErrors = () => {
    const { model } = this.state;
    model.errors = null;
    this.setState({ model });
  };

  render() {
    const show_dialog = this.state.model.settings.token?.show_dialog === true;
    return (
      <Dashboard>
        <div className="container mt-3">
          <div className="row">
            <div className="col-md-12">
              <SearchBox />
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Alert
                clearErrors={this.clearErrors}
                errors={this.state.model.errors}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="btn-group mb-1">
                <input
                  tabIndex=""
                  type="radio"
                  className="btn-check"
                  name="options-outlined"
                  id="success-outlined"
                  autoComplete="off"
                  checked={this.state.model.form.deal === "sell"}
                  onChange={() => null}
                  onClick={() => this.toggleDeal("sell")}
                />
                <label
                  className="btn btn-outline-success px-md-5"
                  htmlFor="success-outlined"
                >
                  Service
                </label>

                <input
                  tabIndex=""
                  type="radio"
                  className="btn-check"
                  name="options-outlined"
                  id="danger-outlined"
                  autoComplete="off"
                  checked={this.state.model.form.deal === "shop"}
                  onChange={() => null}
                  onClick={() => this.toggleDeal("shop")}
                />
                <label
                  className="btn btn-outline-danger px-md-5"
                  htmlFor="danger-outlined"
                >
                  Köpa
                </label>

                <input
                  tabIndex=""
                  type="radio"
                  className="btn-check"
                  name="options-outlined"
                  id="info-outlined"
                  autoComplete="off"
                  checked={this.state.model.form.deal === "opinion"}
                  onChange={() => null}
                  onClick={() => this.toggleDeal("opinion")}
                />
                <label
                  className="btn btn-outline-info px-md-5"
                  htmlFor="info-outlined"
                >
                  Utlåtande
                </label>

                <input
                  tabIndex=""
                  type="radio"
                  className="btn-check"
                  name="options-outlined"
                  id="secondary-outlined"
                  autoComplete="off"
                  checked={this.state.model.form.deal === "fixa"}
                  onChange={() => null}
                  onClick={() => this.toggleDeal("fixa")}
                />
                <label
                  className="btn btn-outline-secondary px-md-5"
                  htmlFor="secondary-outlined"
                >
                  Utlåtande Fixa
                </label>

                <input
                  tabIndex=""
                  type="radio"
                  className="btn-check"
                  name="options-outlined"
                  id="secondary-outlined"
                  autoComplete="off"
                  checked={this.state.model.form.deal === "fixa"}
                  onChange={() => null}
                  onClick={() => this.changeDealText()}
                />
                <label
                  data-toggle="modal"
                  data-target="#staticBackdrop"
                  onClick={() => this.changeDealText()}
                  className="btn btn-outline-secondary px-md-3"
                  htmlFor="secondary-outlined"
                >
                  +
                </label>
              </div>
              <button
                className="btn btn-icon btn-success float-right pr-3"
                onClick={this.addNew}
              >
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-plus-square mr-2"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                  />
                </svg>
                Lägga till
              </button>
            </div>
            <div className="col-md-2"></div>
          </div>
          {/* <!-- 2-4-1-3-2 -->*/}
          <div className="row">
            <div className="col-md-2 text-md-right">
              <label htmlFor="service_number" className="col-form-label">
                Service Nr
              </label>
            </div>
            <div className="col-md-4">
              <div className="col-md-12">
                <input
                  tabIndex=""
                  readOnly={true}
                  style={{ width: "50%", display: "inline-block" }}
                  type="text"
                  className="form-control"
                  id="service_number"
                  placeholder="Skriv service number"
                  value={this.state.model.form.service_number}
                  onChange={this.onChange}
                  required
                />
                <button
                  onClick={this.sammaKund}
                  style={{ float: "right", padding: "0.35rem 0.75rem" }}
                  className="btn btn-primary"
                >
                  Samma Kund
                </button>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
          {/* <!-- 2-4-4-2 -->*/}
          <div className="row mt-2">
            <div className="col-md-2 text-md-right">
              <label htmlFor="name" className="col-form-label">
                Namn
              </label>
            </div>
            <div className="col-md-3">
              <input
                tabIndex=""
                type="text"
                className="form-control"
                id="name"
                placeholder="Skriv namn"
                value={this.state.model.form.name}
                onChange={this.onChange}
                required
              />
            </div>
            <div className="col-md-7"></div>
          </div>
          {/* <!-- 2-4-4-2 -->*/}
          <div className="row mt-2">
            <div className="col-md-2 text-md-right">
              <label htmlFor="telephone" className="col-form-label">
                Tel Nr
              </label>
            </div>
            <div className="col-md-3">
              <input
                tabIndex=""
                type="tel"
                className="form-control"
                id="telephone"
                placeholder="Skriv tel nr"
                value={this.state.model.form.telephone}
                onChange={this.onChange}
                required
              />
            </div>
            <div className="col-md-7"></div>
          </div>
          {/* <!-- 2-4-1-2-3 -->*/}
          <div className="row mt-2">
            {/* Col */}
            <div className="col">
              <div className="row">
                <div className="col-md-4 text-md-right">
                  <label htmlFor="title" className="col-form-label">
                    Märke
                  </label>
                </div>
                <div className="col-md-8">
                  <Select
                    tabIndex=""
                    value={this.state.make.selectedOption}
                    onChange={(selectedOption) =>
                      this.onChangeSelect(selectedOption, "make")
                    }
                    options={this.state.make.options}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary25: "grey",
                        primary: "grey",
                      },
                    })}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-4 text-md-right text-md-right">
                  <label htmlFor="title" className="col-form-label">
                    Enhet
                  </label>
                </div>
                <div className="col-md-8">
                  <Select
                    tabIndex=""
                    value={this.state.product.selectedOption}
                    onChange={(selectedOption) =>
                      this.onChangeSelect(selectedOption, "product")
                    }
                    options={this.state.product.options}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary25: "grey",
                        primary: "grey",
                      },
                    })}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4 text-md-right">
                  <label htmlFor="code" className="col-form-label">
                    Kod
                  </label>
                </div>
                <div className="col-md-3">
                  <input
                    tabIndex=""
                    type="number"
                    className="form-control"
                    id="code"
                    placeholder="Skriv kod"
                    value={this.state.model.form.code}
                    onChange={this.onChange}
                    required
                  />
                </div>

                <div className="col-md-1 text-md-right">
                  <label htmlFor="imei" className="col-form-label">
                    IMEI
                  </label>
                </div>
                <div className="col-md-4">
                  <input
                    tabIndex=""
                    required
                    pattern="\d{4}-\d{2}-\d{2}"
                    type="text"
                    className="form-control"
                    id="imei"
                    placeholder="Skriv imei"
                    value={this.state.model.form.imei}
                    onChange={this.onChange}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-4 text-md-right">
                  <label htmlFor="status" className="col-form-label">
                    Status
                  </label>
                </div>
                <div className="col-md-8">
                  <Select
                    value={this.state.model.status}
                    onChange={(selectedOption) =>
                      this.onChangeSelect(selectedOption, "status")
                    }
                    options={this.state.model.statusOptions}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary25: "grey",
                        primary: "grey",
                      },
                    })}
                  />
                </div>
              </div>
            </div>
            {/* Col */}
            <div className="col">
              {this.state.extras.map((extra, i) => (
                <div key={i} className="row mt-2">
                  <div className="col-md-1"></div>
                  <div className="col-md-6">
                    <div className="form-check-inline btn btn-block btn-outline-success mr-3 p-0 text-left">
                      <CheckButton
                        tabIndex=""
                        id={extra.name}
                        value={this.state.model.existExtra(extra)}
                        label={extra.name + " " + extra.price + "kr"}
                        toggleCheckBox={() => {
                          extra.selected = !extra.selected;
                          this.state.model.addExtra(extra);
                          this.state.model.calculateDiscountedPrice();
                          this.forceUpdate();
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
              {this.state.model.form.inspection && (
                <div className="row mt-2">
                  <div className="col-md-1"></div>
                  <div className="col-md-6">
                    <div className="form-check-inline btn btn-block btn-outline-success mr-3 p-0 text-left">
                      <CheckButton
                        tabIndex=""
                        id="inspection"
                        value={this.state.model.form.inspection}
                        label="Undersökning 100kr"
                        toggleCheckBox={this.toggleCheckBox}
                      />
                    </div>
                  </div>
                </div>
              )}

              {this.state.model.form.express && (
                <div className="row mt-2">
                  <div className="col-md-1"></div>
                  <div className="col-md-6">
                    <div className="form-check-inline btn btn-block btn-outline-warning mr-3 p-0 text-left">
                      <CheckButton
                        tabIndex=""
                        id="express"
                        value={this.state.model.form.express}
                        label="Express 100kr"
                        toggleCheckBox={this.toggleCheckBox}
                      />
                    </div>
                  </div>
                </div>
              )}

              {this.state.model.form.screen_cover && (
                <div className="row mt-2">
                  <div className="col-md-1"></div>
                  <div className="col-md-6">
                    <div className="form-check-inline btn-block btn btn-outline-info mr-3 p-0 text-left">
                      <CheckButton
                        tabIndex=""
                        id="screen_cover"
                        value={this.state.model.form.screen_cover}
                        label="Glasskydd 100kr"
                        toggleCheckBox={this.toggleCheckBox}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>{" "}
            {/* /Col */}
          </div>{" "}
          {/* /Row */}
          {/* <!-- 2-4-4-2 -->*/}
          <div className="row mt-2">
            <div className="col-md-2 text-md-right"></div>
            <div className="col-md-4 mb-3">
              <h4>Tjänst</h4>
              {/* props : { list:[], collection:string in state, action:function, icon_type:add.remove } */}
              <ListGroup
                list={this.state.service._filter}
                action={this.removeFromService}
                icon_type="add"
              />
            </div>

            <div className="col-md-4">
              <h4>Fixa</h4>
              <ListGroup
                list={this.state.model.form.fix?.list}
                action={this.removeFromFix}
                icon_type="remove"
              />
            </div>
            <div className="col-md-2"></div>
          </div>
          {/* <!-- 2-4-4-2 -->*/}
          <div className="row mb-3">
            <div className="col-md-2 text-md-right">
              <label htmlFor="code" className="col-form-label">
                Pris
              </label>
            </div>
            <div className="col-md-4">
              <div className="input-group">
                <input
                  tabIndex=""
                  type="number"
                  className="form-control"
                  id="discountedPrice"
                  placeholder="Skriv pris"
                  value={this.state.model.form.discountedPrice}
                  onChange={this.onChange}
                  onMouseEnter={this.onMouseEnter}
                  required
                />
                <input
                  tabIndex=""
                  type="number"
                  className="form-control"
                  id="discount"
                  placeholder="Skriv rabatt"
                  value={this.state.model.form.discount}
                  onChange={this.onChange}
                  onMouseEnter={this.onMouseEnter}
                  required
                />
              </div>
            </div>
            <div className="col-md-1">
              <label htmlFor="title" className="col-form-label">
                Betalningset
              </label>
            </div>
            <div className="col-md-3">
              <Select
                value={this.state.model.paymentMethod}
                onChange={(selectedOption) =>
                  this.onChangeSelect(selectedOption, "payment_method")
                }
                options={this.state.model.paymentMethods}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary25: "grey",
                    primary: "grey",
                  },
                })}
              />
            </div>
            <div className="col-md-2"></div>
          </div>
          {/* <!-- 2-4-4-2 -->*/}
          <div className="row   mt-2">
            <div className="col-md-2 text-md-right">
              <label htmlFor="technical_info" className="col-form-label">
                Teknikar Info
              </label>
            </div>
            <div className="col-md-8">
              <textarea
                tabIndex=""
                className="form-control"
                id="technical_info"
                placeholder="Skriv teknikar info"
                value={this.state.model.form.technical_info}
                onChange={this.onChange}
                required
                rows="3"
              />
            </div>
            <div className="col-md-2"></div>
          </div>
          {/* <!-- 2-4-4-2 -->*/}
          <div className="row mt-3">
            <div className="col-md-2 text-md-right"></div>
            <div className="col-md-2">
              <div className="form-check-inline btn btn-outline-success mr-3 p-0">
                <CheckButton
                  id="customer_picked"
                  value={this.state.model.form.customer_picked}
                  label="Kunden har hämtas"
                  toggleCheckBox={this.toggleCheckBox}
                />
              </div>
            </div>
            <div className="col-md-1 text-md-right">
              <button
                onClick={() => {
                  this.state.model.refreshDateTimePicked("date_picked");
                  this.forceUpdate();
                }}
                className="btn btn-light btn-refresh-datetime"
              >
                Datum
              </button>
            </div>
            <div className="col-md-2">
              <input
                tabIndex=""
                type="date"
                className="form-control"
                id="date_picked"
                placeholder="Skriv datum"
                value={this.state.model.form.date_picked}
                onChange={this.onChange}
                required
              />
            </div>
            <div className="col-md-1 text-md-right">
              <button
                onClick={() => {
                  this.state.model.refreshDateTimePicked("time_picked");
                  this.forceUpdate();
                }}
                className="btn btn-light btn-refresh-datetime"
              >
                Tid
              </button>
            </div>
            <div className="col-md-2">
              <input
                tabIndex=""
                type="time"
                className="form-control"
                id="time_picked"
                placeholder="Skriv hämta tid"
                value={this.state.model.form.time_picked}
                onChange={this.onChange}
                required
              />
            </div>
          </div>
          {/* <!-- 2-4-4-2 -->*/}
          <div className="row mt-3">
            <div className="col-md-2 text-md-right">
              <label htmlFor="paras" className="form-label">
                Info
              </label>
            </div>
            <div className="col-md-5">
              <textarea
                tabIndex=""
                className="form-control"
                id="info"
                placeholder="Skriv info"
                value={this.state.model.form.info}
                onChange={this.onChange}
                required
                rows="7"
              />
            </div>
            <div className="col-md-3">
              <div className="form-check-inline btn btn-block btn-outline-success mr-3 p-0 text-left">
                <CheckButton
                  tabIndex=""
                  id="garanti_ingen"
                  value={this.state.model.form.garanti_ingen}
                  label="Ingen garanti"
                  toggleCheckBox={this.toggleCheckBox}
                />
              </div>
              <div className="form-check-inline btn btn-block btn-outline-success mr-3 p-0 text-left">
                <CheckButton
                  tabIndex=""
                  id="garanti_3_months"
                  value={this.state.model.form.garanti_3_months}
                  label="3 månader"
                  toggleCheckBox={this.toggleCheckBox}
                />
              </div>
              <div className="form-check-inline btn btn-block btn-outline-success mr-3 p-0 text-left">
                <CheckButton
                  tabIndex=""
                  id="garanti_6_months"
                  value={this.state.model.form.garanti_6_months}
                  label="6 månader"
                  toggleCheckBox={this.toggleCheckBox}
                />
              </div>
              <div className="form-check-inline btn btn-block btn-outline-success mr-3 p-0 text-left">
                <CheckButton
                  tabIndex=""
                  id="garanti_1_year"
                  value={this.state.model.form.garanti_1_year}
                  label="1 år garanti"
                  toggleCheckBox={this.toggleCheckBox}
                />
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
          {/* <!-- 1-1-1 -->*/}
          <div className="row mt-3 mb-5">
            <div className="col-md-4"></div>
            <div className="col-md-2">
              <button
                onClick={this.create}
                className="btn btn-success btn-block mb-2"
              >
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-file-earmark-plus mr-2"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9 1H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h5v-1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h5v2.5A1.5 1.5 0 0 0 10.5 6H13v2h1V6L9 1z" />
                  <path
                    fillRule="evenodd"
                    d="M13.5 10a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M13 12.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0v-2z"
                  />
                </svg>
                Spara
              </button>
            </div>
            <div className="col-md-2">
              <PrintButton create={this.create} show_dialog={show_dialog} />
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
        <div>
          {this.state.model.form.deal === "opinion" ? (
            <Modal size="modal-xl">
              {" "}
              <Opinion model={this.state.model} />{" "}
            </Modal>
          ) : null}
          {this.state.model.form.deal === "fixa" ? (
            <Modal size="modal-xl">
              {" "}
              <Fix model={this.state.model} />{" "}
            </Modal>
          ) : null}
          
          {this.state.model.form.deal === "shop" ? (
            <Modal size="">
              {" "}
              <Shop settings={this.props.settings} model={this.state.model} />
            </Modal>
          ) : null}

          {["sell"].includes(this.state.model.form.deal) ? (
            <Modal size="">
              <Sell settings={this.props.settings} model={this.state.model} />
            </Modal>
          ) : null}

          <Dialog size="modal-xl">
            <input
              tabIndex=""
              type="text"
              className="form-control"
              id="deal_title"
              placeholder="Skriv titl"
              value={this.state.model.form.deal_title}
              onChange={this.onChange}
              required
            />
          </Dialog>
        </div>

        <div
          style={{
            position: "absolute",
            top: 100,
            right: 25,
          }}
        >
          {this.props.toasts.list.map((toast) => (
            <Toast
              key={toast.id}
              style={{
                position: "relative",
                top: 100,
                right: 25,
              }}
              show={true}
              onClose={() =>
                this.props.removeFlashMessageAction({ id: toast.id })
              }
            >
              <Toast.Header>
                <img
                  src="/images/favicon.svg"
                  className="rounded mr-2"
                  alt=""
                />
                <strong className="mr-auto">{toast.type}</strong>
                <small className="ml-2">11 mins ago</small>
              </Toast.Header>
              <Toast.Body>{toast.message}.</Toast.Body>
            </Toast>
          ))}
        </div>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  makes: state.makes,
  products: state.products,
  services: state.services,
  extras: state.extras,
  orders: state.orders,
  toasts: state.toasts,
  settings: state.settings.list[0],
  create: state.orders.create,
});

const mapActionsToProps = {
  createAction: models.orders.actions.create,
  updateAction: models.orders.actions.update,
  readActionServices: models.services.actions.read,
  readActionOrders: models.orders.actions.read,
  addFlashMessageAction: models.toasts.actions.addList,
  removeFlashMessageAction: models.toasts.actions.deleteList,
};

Form.defaultProps = {
  settings: settings,
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(Form));
