import React from "react";

export default function AttFixa({ model, title }) {
  return (
    <table className="table table-responsive fixa table-borderless mt-3">
      <thead>
        <tr>
          <th colSpan="2"> {title ? title: 'Att fixa' } </th>
        </tr>
      </thead>
      <tbody>
        {model.form.fix?.list.map((f, i) => {
          return (
            <tr className="row" key={i}>
              <td className="col-6">
                {f.name} {f.product ? " - " + f.product.name : ""}
              </td>
              <td style={{ paddingRight: "1rem" }} className="col-6">
                {f.price} kr
              </td>
            </tr>
          );
        })}

        {model.form.screen_cover ? (
          <tr key="kr100_sc" className="row">
            <td className="col-6">Screen Cover</td>
            <td style={{ paddingRight: "1rem" }} className="col-6">
              100 kr
            </td>
          </tr>
        ) : null}

        {model.form.express ? (
          <tr key="kr100_exp" className="row">
            <td className="col-6">Express</td>
            <td style={{ paddingRight: "1rem" }} className="col-6">
              100 kr
            </td>
          </tr>
        ) : null}
        {model.form.inspection ? (
          <tr key="kr100_insp" className="row">
            <td className="col-6">Undersökning</td>
            <td style={{ paddingRight: "1rem" }} className="col-6">
              100 kr
            </td>
          </tr>
        ) : null}
        {model.form.extras &&
          model.form.extras.map((extra) => (
            <tr key="extra_price" className="row">
              <td className="col-6">{extra.name}</td>
              <td style={{ paddingRight: "1rem" }} className="col-6">
                {extra.price} kr
              </td>
            </tr>
          ))}

        <tr key="discount_amount" className="row">
          <td className="col-6">Rabatt</td>
          <td style={{ paddingRight: "1rem" }} className="col-6">
            <strong>-</strong>
            {model.discount_amount} kr
          </td>
        </tr>
        <tr
          key="summa"
          className="row"
          style={{ borderTop: "1px solid lightgrey" }}
        >
          <td className="col-6">
            <strong>Summa</strong>
          </td>
          <td style={{ paddingRight: "1rem" }} className="col-6">
            {model.form.discountedPrice} kr
          </td>
        </tr>

        <tr key="payment_method" className="row pt-3">
          <td className="col-6">Betalningset</td>
          <td className="col-6">{model.form.payment_method}</td>
        </tr>

      </tbody>
    </table>
  );
}
