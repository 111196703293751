class Service {
    id = '';
    name = '';
    price = '';
    product_id = '';
    list = [];
    _optionsFiltered = [];
    loaded = false;
    _filter = [];

    constructor(services = false) {
        this.setList(services.list);
    }

    setList(list) {
        if (list && list.length > 0 && this.loaded === false) {
            this.list = this._filter = list;
            this.loaded = true;
            const service = list[0];
            this.id = service.id;
            this.name = service.name;
            this.product_id = service.product_id;
        }
    }

    get options() {
        return this._filter.map(service => ({value: service.id, label: service.name, product_id: service.product_id}));
    }

    set filter(product) {
        if(product) {
            this._filter = this.list.filter(service => service.product_id === product.id);
        }
    }

    get selectedOption() {
        return {value: this.id, label: this.name, name: this.name, price: this.price, product_id: this.product_id};
    }

    set selectedOption(option) {
        this.id = option.value;
        this.name = option.label;
        this.product_id = option.product_id;
    }

    addToFix = (item) => {
        this._optionsFiltered = this._optionsFiltered.filter(service => service.id !== item.id);
        console.log(item, this._optionsFiltered);
    }

    clear() {
        this._filter = [];
    }
}

export default Service;
