import React from 'react';
import OrderList from "./OrderList";


class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                type: '',
                title: "",
                paras: "",
                code: ''
            },
        }
    }

    render() {
        return (
            <div
                className="modal fade mt-5 mb-5" id="featuretteModal" tabIndex="-1"
                aria-labelledby="featuretteModalLabel"
                aria-hidden="true">
                <div style={{minWidth: 'calc(100% - 250px)'}} className="modal-dialog modal-dialog-centered  mt-5">
                    <div className="modal-content">
                        <div className="modal-header shadow">
                            <h5 className="modal-title" id="exampleModalLabel">
                                <svg width="1em" height="1em" viewBox="0 0 16 16"
                                     className="bi bi-search text-success mr-2"
                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                                    <path fillRule="evenodd"
                                          d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                                </svg>
                                Search
                            </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{maxHeight: 'calc(100vh - 230px)'}}>
                            <div className="container-fluid">
                                <div className="row">
                                    <OrderList/>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer" style={{boxShadow: '0 -0.5rem 1rem rgba(0,0,0,0.15)'}}>
                            <button onClick={this.props.toggleOrderList} type="button"
                                    className="btn btn-secondary pr-3"
                                    data-dismiss="modal">
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16"
                                     className="bi bi-x-square text-white-50 mr-2"
                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                    <path fillRule="evenodd"
                                          d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"/>
                                    <path fillRule="evenodd"
                                          d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"/>
                                </svg>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Modal;
