import React from "react";
import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <>
                <Navbar/>
                <div style={{overflow: 'hidden'}} className="">
                    <div style={{display: 'flex', flex: 1}}>
                        <Sidebar/>
                        <main className="col-sm-12 col-md-11 col-lg-11 ml-sm-auto px-md-4">

                            <div className="container-fluid content">
                                <div className=" row">
                                    <div className=" col-12">
                                        {this.props.children}
                                    </div>
                                </div>
                            </div>

                        </main>
                    </div>
                </div>
            </>
        )
    }
}

export default Dashboard;
