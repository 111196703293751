import React from "react";
import moment from 'moment';
import {chunk} from 'lodash';
import {Link} from "react-router-dom";
import {invert} from "lodash";

import {statuses} from "../../common/constants";
import Order from '../order/Order';

const FIX_ROW_ITEMS_COUNT = 3;

const Table = props => {
    const {table, push} = props;
    const iStatuses = invert(statuses);
    if (!table) return <div>Loading...</div>;

    return (
        <table className="table caption-top table-hover">
            <caption>{table.caption} </caption>
            <thead style={{borderBottom: '2px solid lightgrey'}}>
            <tr>
                <th scope="col">#</th>
                {table.cols.map((col, i) => <th key={i} scope="col">{col}</th>)}
                <th scope="col" className="text-center">Action</th>
            </tr>
            </thead>
            <tbody id="tbody">

            {table.rows.map((row, r) =>
                <tr key={r} style={Order.style(row)}>
                    <th scope="row">{r + 1}</th>
                    <td>{row['service_number']}</td>
                    <td>{row['telephone']}</td>
                    <td>{row['name']}</td>
                    <td>{row['product'] ? row['product'].name : 'na'}</td>
                    <td>
                        {row['fix'] && (chunk(row['fix']['list'], FIX_ROW_ITEMS_COUNT)).map((arr, i) =>
                            <p key={i}> {(arr.map(service => service.name)).join(', ')} </p>
                        )}
                    </td>
                    <td>
                        {(moment(row['date_picked'])).format('YYYY-MM-DD')}
                    </td>
                    <td>
                        {(moment(row['time_picked'])).format('HH:mm')}
                    </td>
                    <td>{iStatuses[row['status']]}</td>
                    <td scope="row" className="text-right">
                        <button onClick={(e) => props.onFinish(e, row.id)} className="btn btn-sm btn-warning mr-2 pr-3">
                            <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" className="bi bi-check-square mr-2"
                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                <path fillRule="evenodd"
                                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"/>
                            </svg>

                        </button>
                        {
                            props.onDelete && <button id={row.id} onClick={() => props.onDelete(row.id)}
                                                      className="btn btn-sm btn-danger pr-3 mr-2">
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-trash mr-2"
                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                    <path fillRule="evenodd"
                                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg>

                            </button>
                        }

                        <Link to={`/receipt/${row.id}`}
                              onClick={ () => window.location.href = `/receipt/${row.id}` } // document.getElementById('hide-order-list').click() 
                              className="btn btn-sm btn-info pr-3">
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-pencil-square mr-2"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fillRule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                                Redigera
                        </Link>
                        <button style={{display: 'none'}} id="hide-order-list" type="button" className="close"
                                data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    )
};

export default Table;
