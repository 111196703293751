import React from "react";
import Barcode from "react-barcode";
import SellPaid from "./SellPaid";
import SellUnpaid from "./SellUnpaid";
import Opening from "./Opening";
import AttFixa from "./AttFixa";

class Sell extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("componentDidMount PrintPreview");
    const myModalEl = document.getElementById("modelPrintPreview");
    if (myModalEl) {
      myModalEl.addEventListener("shown.bs.modal", function (e) {
        // document.getElementById('start-print').click();
        console.log("Sum inside pre");
      });
    }
  }

  componentWillReceiveProps(nextProps, context) {
    console.log("Settings", nextProps);
  }

  render() {
    const { model, settings } = this.props;
    let style = {};
    if (model.settings.page_setup?.token?.dimensions) {
      const { dimensions, margins } = model.settings.page_setup.token;
      style = {
        width: dimensions.width + "mm",
        height: dimensions.height + "mm",
        margins: `${margins.top}mm ${margins.left}mm ${margins.bottom}mm ${margins.left}mm`,
      };
    }
    let garanti_count = "";
    if (model.form.garanti_ingen) {
      garanti_count = "Med ingen garanti!";
    }
    if (model.form.garanti_3_months) {
      garanti_count = "Med 3 månader garanti...utan Skada!";
    }
    if (model.form.garanti_6_months) {
      garanti_count = "Med 6 månader garanti...utan Skada!";
    }
    if (model.form.garanti_1_year) {
      garanti_count = "Med 1 år garanti...utan Skada!";
    }

    return (
      <div className="print-receipt">
        <div className="row p-0">
          <div className="col">
            <div
              className="py-2 px-3 text-center"
              style={{ border: "3px solid black", width: "100%" }}
            >
              <h1 style={{ margin: "10px" }}>
                <img
                  style={{ width: "100%" }}
                  src="/images/logo-black-n-white.jpeg"
                  alt="Faiz Fix"
                />
              </h1>
              <p className="text-left px-0 m-0">
                <strong>Tel :</strong> {model.settings.token?.telephone}
              </p>
              <p className="text-left px-0 m-0">
                {model.settings.token?.email} / Org nr
                {" " + model.settings.token?.organization_number}
              </p>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingRight: "0.2rem" }}>
          <div className="col-12 receipt-body">
            {model.form.customer_picked ? (
              <SellPaid model={model} />
            ) : (
              <SellUnpaid model={model} />
            )}

            <AttFixa model={model} />
          </div>
          <div className="col-12">
            <div
              className="opening-hours py-2"
              style={{ borderTop: "2px solid black" }}
            >
              <Opening model={model} />
            </div>
          </div>
          <div className="col-12">
            <div className="notice text-center">
              {model.settings?.token?.notice.split("\n").map((para, i) => (
                <p key={i}>{para}</p>
              ))}
              <p>{garanti_count}</p>
              <Barcode
                height={30}
                value={
                  model.form.service_number
                    ? model.form.service_number
                    : "000000"
                }
              />
            </div>
          </div>
        </div>
        {settings.token.short_kvitto && (
          <p style={{ pageBreakBefore: "always" }}></p>
        )}

        {/* <!-- small kvitto --> */}
        {settings.token.short_kvitto && (
          <div className="row">
            <div className="col-12 receipt-body">
              <table className="table table-borderless table-responsive mb-0">
                <tbody>
                  <tr>
                    <th>Service Nr</th>
                    <td>{model.form.service_number}</td>
                  </tr>
                  <tr>
                    <th>Namn</th>
                    <td>{model.form.name}</td>
                  </tr>
                  <tr>
                    <th>Tel</th>
                    <td>{model.form.telephone}</td>
                  </tr>
                  <tr>
                    <th>Enhet</th>
                    <td>{model.form.product.name}</td>
                  </tr>

                  <tr>
                    <th>Hämta</th>
                    <td>
                      {model.form.date_picked} / {model.form.time_picked}{" "}
                    </td>
                  </tr>

                  <tr>
                    <th>Kod</th>
                    <td>{model.form.code} </td>
                  </tr>
                </tbody>
              </table>

              <AttFixa model={model} />
            </div>

            <div> 
              <p style={{margin: '0'}}><b>Info</b></p>
              <p style={{ width: '100%', textAlign: 'left' }} key="payment_info">
                {model.form.info} 
              </p>
            </div>
            <Barcode
              height={30}
              value={
                model.form.service_number ? model.form.service_number : "000000"
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default Sell;
