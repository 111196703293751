import ActiveRecord from './base/ActiveRecord';

class Settings extends ActiveRecord {

    constructor(name) {
        super(name);
        this.form = {
            email: '',
            password: '',
            quiz: [],
        };
    }
}

export default Settings;
