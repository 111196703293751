import axios from 'axios';

// Set token in all axios requests
const setAuthorizationToken = token => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

export default setAuthorizationToken;
