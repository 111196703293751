import React from "react";
import moment from "moment";

export default function ShopUnpaid({ model }) {
  const dateCreated = (_id) => {
    if (_id) {
      const timeStamp = parseInt(_id.substr(0, 8), 16) * 1000;
      const date = new Date(timeStamp);
      return moment(timeStamp).format("YYYY-MM-DD HH:mm");
      // return date.getDay();
    }
    return "na";
  };
  return (
    <>
    <div>
        <p colSpan={2} style={{ textAlign: 'center', fontWeight:'bold', fontSize:'18px', margin: '0'}}> Inköpsenhet </p>
        <p colSpan={2} style={{ textAlign: 'center', fontWeight:'bold', fontSize:'18px'}}>{dateCreated(model.form._id)}</p>
    </div>
    <table className="table table-borderless table-responsive mb-0">
      <tbody>
     
        <tr>
          <th>Namn</th>
          <td>{model.form.name}</td>
        </tr>
        <tr>
          <th>Tel</th>
          <td>{model.form.telephone}</td>
        </tr>
        <tr>
          <th>Enhet</th>
          <td>{model.form.product.name}</td>
        </tr>

        <tr>
          <th>IMEI</th>
          <td>
            {model.form.imei} 
          </td>
        </tr>
      </tbody>
      </table>
      </>
  );
}
