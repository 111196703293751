import React from "react";

class Full extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (

            <div className="d-flex align-items-center justify-content-center" style={{height: '100vh'}}>

                {this.props.children}

            </div>
        )
    }
}

export default Full;
