import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import models from '../store/models';
import Full from "../containers/Full";

import "./login.css";

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: 'admin@admin.com',
            password: 'admin',
            login: models.logins,
            errors: false
        }
    }

    handleChange = (e) => {
        e.preventDefault();
        const field = e.target.id;
        const value = e.target.value;
        this.setState({[field]: value});
    };

    handleLogin = (e) => {
        e.preventDefault();
        const {createAction} = this.props;
        const user = {
            email: this.state.email,
            password: this.state.password
        };
        createAction({user});
    };

    componentDidMount() {
        if (this.authenticated(this.props)) {
            // this.props.history.push('/receipt');
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {logins} = nextProps;
        console.log('componentWillReceiveProps', logins);
        if (this.authenticated(nextProps)) {
            this.props.history.push('/receipt');
        } else {
            const errors = {
                type: 'alert-danger',
                header: 'Fel',
                errors: logins.form
            };
            this.setState({errors});
        }
    }

    authenticated = (props) => {
        const {logins, addFlashMessageAction} = props;
        const login = (logins && logins.success && logins.form && logins.form[0] && logins.form[0].email);
        if (login) {
            addFlashMessageAction({type: 'success', message: 'Login successful'});
        }
        return login;
    };

    onKeyDown = e => {
        if (e.key === 'Enter') {
            this.handleLogin(e);
        }
    };

    render() {
        if (this.authenticated(this.props)) this.props.history.push('/receipt');
        return (
            <Full>
                <div>
                    <div className="row">
                        <div className="col-md-12 order-md-0">
                            <div className="wrapper fadeInDown my-5">
                                <div id="formContent">

                                    <div className="fadeIn first">
                                        <img src="/images/user-avatar.png" className="pb-4"
                                             style={{width: "120px", marginTop: '-35px'}}
                                             id="icon" alt="User Icon"/>
                                    </div>

                                    <form className="login px-5 text-left  was-validated" method="post">
                                        <div className="row">
                                            <div className="col">
                                                <label htmlFor="email"
                                                       className="fadeIn second form-label">E-post</label>
                                                <input onChange={this.handleChange} type="email" id="email"
                                                       className="fadeIn second form-control"
                                                       name="email"
                                                       value={this.state.email}
                                                       placeholder="E-post"
                                                       autoComplete="off"
                                                       onKeyDown={this.onKeyDown}
                                                       required={true}
                                                />
                                                <div className="invalid-feedback">
                                                    Please provide a valid email.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col">
                                                <label htmlFor="password"
                                                       className="fadeIn third form-label">Lösenord</label>
                                                <input onChange={this.handleChange} type="password" id="password"
                                                       className="fadeIn third form-control"
                                                       name="password"
                                                       value={this.state.password}
                                                       placeholder="Password"
                                                       onKeyDown={this.onKeyDown}
                                                       required={true}/>
                                                <div className="invalid-feedback">
                                                    Please provide a password.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col text-center">
                                                <input onClick={this.handleLogin} type="button"
                                                       className="align-self-center btn btn-outline-success fadeIn fourth mt-5"
                                                       value="Log In"/>
                                            </div>
                                        </div>

                                    </form>

                                    <div id="formFooter">
                                        <a className="link-info underlineHover py-3" href="/api/register">Register</a>
                                        <a className="link-info underlineHover py-3" href="/api/forgot-password">Forgot
                                            Password?</a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Full>

        )
    }
}

/**
 * Get data from store
 * @param state
 */
const mapStateToProps = state => ({
    logins: state.logins,
    toasts: state.toasts,
});

/**
 * Import action from dir action above - but must be passed to connect method in order to trigger reducer in store
 * @type {{readAction: UserReadAction}}
 */
const mapActionsToProps = {
    createAction: models.logins.actions.create,
    addFlashMessageAction: models.toasts.actions.addList
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(Login));
