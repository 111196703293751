import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import models from "../../store/models";
import Dashboard from "../../containers/Dashboard";
import List from "./List";
import Alert from "../Alert";
import CheckButton from "../CheckButton";


class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                email: '',
                password: '',
                is_admin: false
            },
            list: [],
            errors: false,
            isNewRecord: true
        }
    }

    onChange = e => {
        const {form} = this.state;
        form[e.target.id] = e.target.value;
        this.setState({form});
    };

    onSubmit = e => {
        e.preventDefault();
        const {createAction, updateAction} = this.props;
        if (this.state.isNewRecord) {
            createAction({user: this.state.form});
        } else {
            updateAction({user: this.state.form, id: this.state.form.id});
        }
    };

    componentDidMount() {
        console.log("componentDidMount");
        this.doUpdate(this.props);
    }

    doUpdate = props => {
        const {list, form, actions, success} = props.users;
        let errors = {};

        switch (actions) {
            case 'create_success':
                Object.keys(form).map(key => form[key] = '');
                errors = {
                    type: 'alert-success',
                    header: 'Success',
                    errors: [{Saved: {message: 'User saved !'}}],
                    done: true
                };

                this.setState({form: {email: '', password: ''}, errors});
                console.log('Form - create success')
                this.props.readAction({});
                break;
            case 'update_success':
                Object.keys(form).map(key => form[key] = '');
                errors = {
                    type: 'alert-success',
                    header: 'Success',
                    errors: [{Updated: {message: 'User updated !'}}],
                    done: true
                };

                this.setState({form: {email: '', password: ''}, errors, isNewRecord: true});
                console.log('Form - create success')
                this.props.readAction({});
                break;
            case 'create_fail':
                //alert(JSON.stringify(form));
                errors = {
                    type: 'alert-danger',
                    header: 'Errors',
                    errors: form
                };
                this.setState({errors});
                break;
            case 'read_success':
                if (success) {
                    this.setState({list});
                } else {
                    this.setState({list: []});
                }
                break;

            case 'edit':
                if (success && this.state.isNewRecord === true) {
                    this.setState({form: {...form}, isNewRecord: false});
                } else {
                    // show create error
                }
                break;

            case 'delete_success':
                if (success) {
                    this.props.readAction({});
                    const errors = {
                        type: 'alert-info',
                        header: 'Delete',
                        errors: [{Deleted: {message: 'User deleted !'}}],
                        done: true
                    };
                    this.setState({errors});
                    this.props.readAction({});
                } else {
                    // show create error
                }
                break;
        }

        setTimeout(() => this.setState({errors: false}), 4000);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        this.doUpdate(nextProps);
    }

    toggleCheckBox = field => {
        const {form} = this.state;
        form.is_admin = !form.is_admin;
        this.setState({form});
    };

    addNew = e => {
        e.preventDefault();
        const form = {email: '', password: '', is_admin: false};
        this.setState({form, isNewRecord: true});
    };

    render() {
        return (
            <Dashboard>
                <div className="container my-md-5">
                    <form className="needs-validation" noValidate id="form-user" action="/api/v1/users">
                        <h3 className="mb-3">Users</h3>
                        <Alert errors={this.state.errors}/>
                        <div className="row g-3">
                            <div className="col-md-4">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="user[email]"
                                    placeholder="Enter email of the user"
                                    value={this.state.form.email}
                                    onChange={this.onChange}
                                    required
                                />
                                <div className="invalid-feedback">
                                    Valid email is required.
                                </div>
                            </div>
                        </div>
                        <div className="row g-3 mt-1">
                            <div className="col-md-4">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="user[password]"
                                    placeholder="Enter password of the user"
                                    value={this.state.form.password}
                                    onChange={this.onChange}
                                    required
                                />
                                <div className="invalid-feedback">
                                    Valid password is required.
                                </div>
                            </div>
                        </div>

                        <div className="row g-3 mt-1">
                            <div className="col-md-4">
                                <label htmlFor="password" className="form-label d-block">Admin</label>

                                    <div className="form-check-inline btn btn-outline-info mr-3 p-0 text-left">
                                        <CheckButton tabIndex="" id="is_admin" value={this.state.form.is_admin}
                                                     label={<span className="d-inline-block pr-5">admin</span>} toggleCheckBox={this.toggleCheckBox}/>
                                    </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 d-flex d-inline-flex">
                                <button className="btn btn-icon btn-success pr-3 mt-5 mr-3" onClick={this.addNew}>
                                    <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" className="bi bi-plus-square mr-2"
                                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z"/>
                                        <path fillRule="evenodd"
                                              d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z"/>
                                        <path fillRule="evenodd"
                                              d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                    </svg>
                                    Lägga till
                                </button>
                                <button className="btn btn-icon btn-primary pr-5 mt-5 mr-3" onClick={this.onSubmit}>
                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16"
                                         className="bi bi-file-earmark-plus mr-2" fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9 1H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h5v-1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h5v2.5A1.5 1.5 0 0 0 10.5 6H13v2h1V6L9 1z"/>
                                        <path fillRule="evenodd"
                                              d="M13.5 10a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"/>
                                        <path fillRule="evenodd"
                                              d="M13 12.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0v-2z"/>
                                    </svg>
                                    Spara
                                </button>
                            </div>
                        </div>

                    </form>
                    <div className="table-responsive">
                        <List table={{
                            cols: ['Email', 'Admin'],
                            fields: ['email', 'is_admin'],
                            rows: this.state.list
                        }}/>
                    </div>


                </div>
            </Dashboard>
        )
    }
}

const mapStateToProps = state => ({
    users: state.users
});

const mapActionsToProps = {
    readAction: models.users.actions.read,
    createAction: models.users.actions.create,
    updateAction: models.users.actions.update
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(Form));
