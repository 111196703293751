import React from 'react';
import Modal from "./Modal";
import models from "../../store/models";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {padStart} from "lodash";


class SearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: {
                service_number: '',
                name: '',
                telephone: ''
            },
            show: false,
            service_number: ''
        }
    }

    componentDidMount() {
        this.service_number_ref.focus();
    }

    onChange2 = (e) => {
        e.preventDefault();
        const order = {service_number: '', name: '', telephone: ''};
        order[e.target.id] = e.target.value;
        this.setState({order});
    };
    onChange = (e) => {
        e.preventDefault();
        const LENGTH = 6;
        const order = {service_number: '', name: '', telephone: ''};
        let service_number = '';

        if (e.target.id === 'service_number' && e.target.value !== '') {
            console.log(e.target.value)
            const num = Number(e.target.value);
            service_number = padStart(num, LENGTH, '0');
            order[e.target.id] = 'A' + service_number;
        } else {
            order[e.target.id] = e.target.value;
        }
        this.setState({order, service_number});
    };
    onKeyDown = e => {
        console.log(e.key);
        if (e.key === 'Enter') {
            e.preventDefault();
            this.props.setNameAction({...this.state.order})
            this.setState({show: true});
        } else {
            this.setState({show: false});
        }
    };

    onDoubleClick = e => {
        e.preventDefault();
        this.props.setNameAction({...this.state.order})
        this.setState({show: true});
    };

    onMouseEnter = e => {
        e.preventDefault();
        e.target.select();
        this.setState({show: false});
    };

    toggleOrderList = e => {
        e.preventDefault();
        const {show} = this.state;
        this.setState({show: !show});
        console.log('show', show);

    };

    render() {
        console.log('searchbox render', this.state.order.service_number)

        return (
            <div className="row">
                <div className="col-md-2 text-left">
                    <h3 className="d-inline-flex">Kvitto </h3>
                    <label htmlFor="service_number" className="col-form-label d-inline-flex text-md-right ml-4">Service
                        Nr</label>
                </div>
                <div className="col-md-2">
                    <div className="input-group">
                        <span className="input-group-text">A</span>
                        <input
                            type="number"
                            className="form-control"
                            id="service_number"
                            placeholder="Skriv service nr"
                            value={this.state.service_number}
                            onChange={this.onChange}
                            onKeyDown={this.onKeyDown}
                            onMouseEnter={this.onMouseEnter}
                            onDoubleClick={this.onDoubleClick}
                            autoComplete="off"
                            ref={ref => this.service_number_ref = ref}
                            tabIndex="0"
                            required
                        />
                        <div className="invalid-feedback">
                            Your username is required.
                        </div>
                    </div>
                </div>
                <div className="col-md-1 text-md-right">
                    <label htmlFor="date" className="col-form-label">Namn</label>
                </div>
                <div className="col-md-2">
                    <input
                        tabIndex=""
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Skriv namn"
                        value={this.state.order.name}
                        onChange={this.onChange}
                        onKeyDown={this.onKeyDown}
                        onMouseEnter={this.onMouseEnter}
                        onDoubleClick={this.onDoubleClick}
                        autoComplete="off"
                        required
                    />
                </div>
                <div className="col-md-1 text-md-right">
                    <label htmlFor="date" className="col-form-label">Telefon</label>
                </div>
                <div className="col-md-2">
                    <input
                        tabIndex=""
                        type="number"
                        className="form-control"
                        id="telephone"
                        placeholder="Skriv telefon"
                        value={this.state.order.telephone}
                        onChange={this.onChange}
                        onKeyDown={this.onKeyDown}
                        onMouseEnter={this.onMouseEnter}
                        onDoubleClick={this.onDoubleClick}
                        autoComplete="off"
                        required
                    />
                </div>
                <div className="col-md-2">
                    {
                        this.state.show && <Modal show={this.state.show} toggleOrderList={this.toggleOrderList}
                                                  service_number={this.state.order.service_number}
                                                  addBody={() => null}/>
                    }
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    searches: state.searches,
});

const mapActionsToProps = {
    setNameAction: models.searches.actions.setName,
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(SearchBox));

