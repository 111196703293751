import ActiveRecord from './base/ActiveRecord';

class Product extends ActiveRecord {

    constructor(name) {
        super(name);
        this.form = {
            name: '',
            make_id: ''
        };
    }
}

export default Product;
