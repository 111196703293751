import React from "react";

const Service = (props) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="input-group">
          <input
            value={props.service.form.name}
            onKeyDown={(e) => props.onKeyDown(e, "service")}
            onChange={(e) => props.onChange(e, "service")}
            id="name"
            type="text"
            className="form-control"
            placeholder="Fel"
          />
          <input
            value={props.service.form.price}
            onKeyDown={(e) => props.onKeyDown(e, "service")}
            onChange={(e) => props.onChange(e, "service")}
            id="price"
            type="text"
            className="form-control"
            placeholder="Pris"
          />
          <input
            value={props.service.form.qty}
            onKeyDown={(e) => props.onKeyDown(e, "service")}
            onChange={(e) => props.onChange(e, "service")}
            id="qty"
            type="text"
            className="form-control"
            placeholder="Antal"
          />
          <div className="input-group-append">
            <button
              onClick={() => props.onCreate("service")}
              type="submit"
              className="btn btn-primary p-2"
            >
              {props.service.buttonLabel}
            </button>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="list-group mt-3"></div>
      </div>
    </div>
  );
};

export default Service;
