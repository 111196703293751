import React from 'react';
import PageSize from "./PageSize";
import {settings} from "../../common/constants";
import models from "../../store/models";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import CheckButton from "../CheckButton";



class General extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: { // should be form/model always, settings is for redux
                token: {
                    service_number_prefix: '',
                    telephone: '',
                    email: '',
                    organization_number: '',
                    notice: '',
                    show_dialog: true,
                    short_kvitto: false
                },
                opening_hours: {
                    monday_friday: ['', ''],
                    saturday: ['', ''],
                    sunday: ['']
                },
                tables: {
                    rows_count: '',
                    data_year: ''
                },
                page_setup: {
                    token: {
                        dimensions: {
                            width: '',
                            height: '',
                        },
                        margins: {
                            top: '',
                            right: '',
                            bottom: '',
                            left: ''
                        }
                    },
                    opinion: {
                        dimensions: {
                            width: '',
                            height: '',
                        },
                        margins: {
                            top: '',
                            right: '',
                            bottom: '',
                            left: ''
                        }
                    }
                }
            }
        }
    }

    componentDidMount() { // no data on page refresh but on click sidebar tab
        console.log('setForm componentDidMount Settings', this.props.settings)
        this.setForm(this.props);
    }

    setForm(props) {
        if (props.settings.success === true && (props.settings.list.length > 0 || props.settings.form.length > 0)) {
            console.log('setForm settings', props.settings);
            const settings = props.settings.actions === 'update_success' ? props.settings.form[0] : props.settings.list[0];
            this.setState({settings});
        } else {
            console.log('setForm default', props.default);
            const {settings} = this.state;
            const {token, opening_hours, tables, page_setup} = props.default;
            this.setState({settings: {p_id: 1, ...settings, opening_hours, token, tables, page_setup}});
        }
    }

    componentWillReceiveProps(nextProps, nextContext) { // list on page refresh, not called on tab click
        console.log('setForm Settings componentWillReceiveProps', nextProps);
        this.setForm(nextProps);
    }

    onSubmit = e => {
        e.preventDefault();
        this.props.updateAction({setting: this.state.settings, id: this.state.settings.p_id})
    };

    onChange = (e, value) => {
        e.preventDefault();
        const [key, subkey] = e.target.id.split('.');
        const {settings} = this.state;
        settings[key] = {...settings[key], ...{[subkey]: value}};
        this.setState({settings});
    };

    toggleCheck = field => {
        const {settings} = this.state;
        settings.token[field] = ! settings.token[field];
        this.setState({settings});
    }

    setPageSetup = (id, value) => {
        const {page_setup} = this.state.settings;
        page_setup[id] = value;
        this.setState({page_setup});
    };

    render() {
        const {tables, opening_hours, token} = this.state.settings;

        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <h3>Token</h3>
                        <div className="row">
                            <div className="col-md-3">
                                <label className="form-label" htmlFor="token.service_number_prefix">Service Nr
                                    prefix</label>
                                <input id="token.service_number_prefix" value={token.service_number_prefix}
                                       onChange={(e) => this.onChange(e, e.target.value)}
                                       type="text" className="form-control" placeholder="Skriv serrvice nr prefix"/>
                            </div>

                            <div className="col-md-3">
                                <label className="form-label" htmlFor="telephone">Telefone</label>
                                <input id="token.telephone" value={token.telephone}
                                       onChange={(e) => this.onChange(e, e.target.value)}
                                       type="text" className="form-control" placeholder="Skriv telefon nr"/>
                            </div>

                            <div className="col-md-3">
                                <label className="form-label" htmlFor="token.email">E-post</label>
                                <input id="token.email" value={token.email}
                                       onChange={(e) => this.onChange(e, e.target.value)}
                                       type="text" className="form-control" placeholder="Skriv e-post"/>
                            </div>

                            <div className="col-md-3">
                                <label className="form-label" htmlFor="token.organization_number">Org nummer</label>
                                <input id="token.organization_number" value={token.organization_number}
                                       onChange={(e) => this.onChange(e, e.target.value)}
                                       type="text" className="form-control" placeholder="Skriv org nr"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="monday_friday" className="col-form-label">MÖN -
                                    FRI </label>
                                <div className="input-group">
                                    <input value={opening_hours.monday_friday[0]} id="opening_hours.monday_friday"
                                           onChange={(e) => this.onChange(e, [e.target.value, opening_hours.monday_friday[1]])}
                                           type="time" className="form-control" placeholder="Lägga till tid"/>
                                    <input value={opening_hours.monday_friday[1]} id="opening_hours.monday_friday"
                                           onChange={(e) => this.onChange(e, [opening_hours.monday_friday[0], e.target.value])}
                                           type="time" className="form-control" placeholder="Lägga till pris"/>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="saturday" className="col-form-label">LÖRDAG </label>
                                <div className="input-group">
                                    <input value={opening_hours.saturday[0]} id="opening_hours.saturday"
                                           type="time"
                                           onChange={(e) => this.onChange(e, [e.target.value, opening_hours.saturday[1]])}
                                           className="form-control"
                                           placeholder="Lägga till fel"/>
                                    <input value={opening_hours.saturday[1]} id="opening_hours.saturday"
                                           type="time"
                                           onChange={(e) => this.onChange(e, [opening_hours.saturday[0], e.target.value])}
                                           className="form-control"
                                           placeholder="Lägga till tid"/>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="opening_hours.sunday" className="col-form-label">SÖNDAG </label>
                                <input value={opening_hours.sunday[0]} id="opening_hours.sunday"
                                       onChange={(e) => this.onChange(e, [e.target.value])}
                                       type="text" className="form-control"/>
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-4">
                                    <label className="form-label" htmlFor="token.notice">Notice</label>
                                    <textarea id="token.notice" value={token.notice}
                                              onChange={(e) => this.onChange(e, e.target.value)}
                                              tabIndex="" className="form-control text-justify"
                                              placeholder="Skriv notice" required rows="5"/>
                                </div>

                                <div className="col-md-4">
                                    <label className="form-label" htmlFor="token.show_dialog">Show print dialog</label>
                                    <div className="form-check-inline btn btn-block btn-outline-warning mr-3 p-0 text-left">
                                        <CheckButton tabIndex="" id="show_dialog" value={token.show_dialog}
                                                    label="Show or hide print dialog" toggleCheckBox={this.toggleCheck}/>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <label className="form-label" htmlFor="token.short_kvitto">Print</label>
                                    <div className="form-check-inline btn btn-block btn-outline-warning mr-3 p-0 text-left">
                                        <CheckButton tabIndex="" id="short_kvitto" value={token.short_kvitto}
                                                    label="Print short kvitto after full" toggleCheckBox={this.toggleCheck}/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-6">
                        <h3>Tables</h3>

                        <div className="row">
                            <div className="col-md-4">
                                <label htmlFor="tables.rows_count" className="form-label">Rows räkning</label>
                                <input value={tables.rows_count} id="tables.rows_count"
                                       onChange={(e) => this.onChange(e, e.target.value)}
                                       className="form-control" type="text" placeholder="Rows räkning" maxLength="3"/>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label" htmlFor="tables.data_year">Data år</label>
                                <input id="tables.data_year" value={tables.data_year}
                                       onChange={(e) => this.onChange(e, e.target.value)}
                                       type="text" className="form-control" placeholder="Skriv data år" maxLength="4"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-12">
                        <h3>Page setup</h3>
                        <ul className="nav nav-tabs" id="page-setup-tabs" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="token-tab" data-toggle="tab" href="#token"
                                   role="tab"
                                   aria-controls="token" aria-selected="true">Token</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className="nav-link" id="opinion-tab" data-toggle="tab" href="#opinion"
                                   role="tab"
                                   aria-controls="opinion" aria-selected="false">Opinion</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="token" role="tabpanel"
                                 aria-labelledby="token-tab">
                                <div className="row">
                                    <div className="col-md-4">
                                        <PageSize id="token" setPageSetup={this.setPageSetup}/>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-4">
                                        <label className="col-form-label">Token</label>
                                        <p> 72 | 210</p>
                                        <p> 10 | 5 | 10 | 5</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="opinion" role="tabpanel"
                                 aria-labelledby="opinion-tab">
                                <div className="row">
                                    <div className="col-md-4">
                                        <PageSize id="opinion" setPageSetup={this.setPageSetup}/>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className="col-md-4">
                                        <label className="col-form-label">A4</label>
                                        <p> 210 | 252</p>
                                        <p> 30 | 10 | 30 | 10</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12">
                        <button className="btn btn-icon btn-primary pr-5 mt-5 mr-3" onClick={this.onSubmit}>
                            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16"
                                 className="bi bi-file-earmark-plus mr-2" fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9 1H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h5v-1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h5v2.5A1.5 1.5 0 0 0 10.5 6H13v2h1V6L9 1z"/>
                                <path fillRule="evenodd"
                                      d="M13.5 10a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"/>
                                <path fillRule="evenodd"
                                      d="M13 12.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0v-2z"/>
                            </svg>
                            Spara
                        </button>
                    </div>
                </div>

            </>
        )
    }
}

General.defaultProps = {
    default: {
        id: 1,
        tables: settings.tables,
        opening_hours: settings.opening_hours,
        token: settings.token,
        page_setup: settings.page_setup,
        settings: {}
    }
};

const mapStateToProps = state => ({
    settings: state.settings,
});

const mapActionsToProps = {
    updateAction: models.settings.actions.update,
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(General));

