import React from "react";

const Product2 = (props) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="input-group">
          <input
            value={props.product.form.name}
            onKeyDown={(e) => props.onKeyDown(e, "product")}
            onChange={(e) => props.onChange(e, "product")}
            id="name"
            type="text"
            className="form-control"
            placeholder="Lägga till enhet"
          />
          <div className="input-group-append">
            <button
              onClick={() => props.onCreate("product")}
              type="submit"
              className="btn btn-primary p-2"
            >
              {props.product.buttonLabel}
            </button>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="list-group mt-3"></div>
      </div>
    </div>
  );
};

export default Product2;
