import React from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import models from "../../store/models";

class List extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log("componentWillReceiveProps");
    }

    onDelete = e => {
        e.preventDefault();
        this.props.deleteAction({id: e.target.id});
    }

    onEdit = e => {
        e.preventDefault();
        this.props.editAction({id: e.target.id});
    };

    render() {
        const {table} = this.props;
        if (!table) return <div>Loading...</div>
        return (
            <table className="table caption-top">
                <caption>{table.caption} </caption>
                <thead style={{borderBottom: '2px solid lightgrey'}}>
                <tr>
                    <th scope="col">#</th>
                    {table.cols.map((col, i) => <th key={i} scope="col">{col}</th>)}
                    <th className="text-right" scope="col">Action</th>
                </tr>
                </thead>
                <tbody id="tbody">
                {table.rows.map((row, r) =>
                    <tr key={r}>
                        <th scope="row">{r + 1}</th>
                        {table.fields.map((field, i) => <td key={r + '_' + i}>{row[field] + ''}</td>)}
                        <td className="text-right" scope="row">
                            <button id={row.id} onClick={this.onDelete} className="btn btn-sm btn-danger pr-3 mr-3">
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-trash mr-2"
                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                    <path fillRule="evenodd"
                                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg>

                            </button>

                            <button id={row.id} onClick={this.onEdit} className="btn btn-sm btn-info pr-3">
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16"
                                     className="bi bi-pencil-square mr-2"
                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                    <path fillRule="evenodd"
                                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                                Redigera
                            </button>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>

        )
    }
}


const mapStateToProps = state => ({
    users: state.users
});

const mapActionsToProps = {
    readAction: models.users.actions.read,
    editAction: models.users.actions.edit,
    deleteAction: models.users.actions.delete,
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(List));
