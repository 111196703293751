import React from 'react';

const ITEMS_COUNT_PER_PAGE = 3;
let TOTAL_ITEMS = 1;
let TOTAL_PAGES = 1;

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.items = Array.from(Array(36).keys());

        this.state = {
            activePage: 1,
            total_pages: 1,
            activeButton: 'prev'
        };
    }

    setPages(props) {
        const {total_items, items_per_page} = props;
        const total_pages = Math.ceil(total_items / items_per_page);
        this.setState({total_pages})
    }

    componentDidMount() {
        this.setPages(this.props);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setPages(nextProps);
    }

    setNext = () => {
        let {activePage, total_pages} = this.state;
        const nextPage = activePage + 1;
        if (nextPage <= total_pages) {
            console.log(total_pages)
            ++activePage;
            this.setState({activePage, activeButton: 'next'});
        }
        this.props.setActivePage(activePage);
    };

    setPrev = () => {
        let {activePage, total_pages} = this.state;
        const nextPage = activePage - 1;
        if (nextPage > 0) {
            --activePage;
            this.setState({activePage, activeButton: 'prev'});
        }
        this.props.setActivePage(activePage);
    };

    setPage = (activePage, activeButton) => {
        this.setState({activePage, activeButton})
        this.props.setActivePage(activePage);
    };

    render() {
        return (
            <div className='row'>
                <div className="col-md-12 text-left">
                    <div className="btn-group pagination">
                        <input tabIndex="" type="button" className="btn-check" name="options-outlined"
                               id="success-outlined"/>
                        <label onClick={() => this.setPrev()}
                               className={"btn btn-outline-success px-md-5" + (this.state.activeButton === 'prev' ? ' active' : '')}
                               htmlFor="success-outlined">Före</label>

                        <input tabIndex="" type="button" className="btn-check" name="options-outlined"
                               id="danger-outlined" autoComplete="off"/>
                        <label onClick={() => this.setPage(1, 'first')}
                               className={"btn btn-outline-danger px-md-5" + (this.state.activeButton === 'first' ? ' active' : '')}
                               htmlFor="danger-outlined">1</label>

                        <input tabIndex="" type="button" className="btn-check" name="options-outlined"
                               id="info-outlined" autoComplete="off"/>
                        <label onClick={() => this.setPage(this.state.total_pages, 'last')}
                               className={"btn btn-outline-info px-md-5"+ (this.state.activeButton === 'last' ? ' active' : '' ) }
                               htmlFor="info-outlined">{this.state.total_pages}</label>

                        <input tabIndex="" type="button" className="btn-check" name="options-outlined"
                               id="warning-outlined" autoComplete="off"
                        />
                        <label onClick={this.setNext} className={"btn btn-outline-warning px-md-5"+ (this.state.activeButton === 'next' ? ' active' : '' )}
                               htmlFor="warning-outlined">Nästa</label>
                    </div>
                </div>
            </div>
        )
    }
}

export default Pagination;
