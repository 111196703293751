import React from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import AdminLi from "./AdminLi";


class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: {
                settings: false,
                users: false,
            }
        }
    }

    render() {
        let {pathname} = this.props.location;
        pathname = pathname.length == 0 ? "/crud" : pathname;

        return (
            <nav id="drawerMobileSelf" className="sidebar col-md-1 col-lg-1 d-md-block collapse">
                <ul className="nav flex-column text-center">
                    <AdminLi props={this.props.logins}>
                        <li className="nav-item text-white">
                            <Link to="/settings" className={pathname == "/settings" ? "active" : ""}>
                            <span>
                              <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-sliders text-muted"
                                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd"
                                        d="M14 3.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zM11.5 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM7 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zM4.5 10a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm9.5 3.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zM11.5 15a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                                  <path fillRule="evenodd"
                                        d="M9.5 4H0V3h9.5v1zM16 4h-2.5V3H16v1zM9.5 14H0v-1h9.5v1zm6.5 0h-2.5v-1H16v1zM6.5 9H16V8H6.5v1zM0 9h2.5V8H0v1z"/>
                                </svg>
                            </span>
                                <span>Inställningar</span></Link
                            >
                        </li>
                    </AdminLi>

                    <AdminLi props={this.props.logins}>
                        <li className="nav-item text-white">
                            <Link to="/user" className={pathname == "/user" ? "active" : ""}>
                                <span>
                                    <svg width="2em" height="2em" viewBox="0 0 16 16"
                                         className="bi bi-person-bounding-box text-muted"
                                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                      <path fillRule="evenodd"
                                            d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5z"/>
                                      <path fillRule="evenodd"
                                            d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                    </svg>

                                </span>
                                <span>Användare</span></Link
                            >
                        </li>
                    </AdminLi>

                    <li className="nav-item text-white">
                        <Link to="/receipt" className={pathname.split('/').includes("receipt") ? "active" : ""}>
                            <span>
                              <svg width="2em" height="2em" viewBox="0 0 16 16"
                                   className="bi bi-receipt-cutoff text-muted"
                                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd"
                                        d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v13h-1V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51L2 2.118V15H1V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zM0 15.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5z"/>
                                  <path fillRule="evenodd"
                                        d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-8a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z"/>
                                </svg>
                            </span>
                            <span>Kvitto</span></Link
                        >
                    </li>

                    <li className="nav-item text-white">
                        <Link to="/order" className={pathname == "/order" ? "active" : ""}>
                            <span>
                              <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-truck"
                                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd"
                                    d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5v7h-1v-7a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5v1A1.5 1.5 0 0 1 0 10.5v-7zM4.5 11h6v1h-6v-1z"/>
                              <path fillRule="evenodd"
                                    d="M11 5h2.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5h-1v-1h1a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4.5h-1V5zm-8 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 1a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                              <path fillRule="evenodd"
                                    d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 1a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                            </svg>
                            </span>
                            <span>Beställer</span></Link
                        >
                    </li>

                    <li className="nav-item text-white">
                        <Link to="/backup" className={pathname == "/backup" ? "active" : ""}>
                            <span>
                              <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-hdd-stack"
                                   fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd"
                                        d="M14 10H2a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1zM2 9a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2H2z"/>
                                  <path d="M5 11.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                  <path fillRule="evenodd"
                                        d="M14 3H2a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM2 2a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
                                  <path d="M5 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                </svg>
                            </span>
                            <span>Backup</span></Link
                        >
                    </li>

                </ul>
            </nav>
        )
    }
}

const mapStateToProps = state => ({
    logins: state.logins
});

const mapActionsToProps = {};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(Sidebar));
