import React from 'react';


export default function Opening({model}) {

    return (
        <table className="table table-responsive table-borderless">
            <tbody>
            <tr>
                <th>MON -FRI</th>
                <td>{model.settings.opening_hours?.monday_friday.join(' - ')}</td>
            </tr>
            <tr>
                <th>LÖRDAG</th>
                <td>{model.settings.opening_hours?.saturday.join(' - ')}</td>
            </tr>
            <tr>
                <th>SÖNDAG</th>
                <td>{model.settings.opening_hours?.sunday.join(' - ')}</td>
            </tr>
            </tbody>
        </table>
    )
}
