import { call, put } from "redux-saga/effects";
import axios from "axios";
import shortid from "shortid";
import { apiServer } from "../../common/constants";
import Database from "./idb";

const IDB_NAME = "receipt";

class Model {
  // Constructor - Name, forceUpdate
  constructor(name) {
    this.name = name;
    this.forceUpdate = () => null;
    this.server = apiServer + "/api/v1/" + name;
    this.debug = false;
  }

  forceUpdate = () => null;

  // Subscribers

  // CRUD constants
  get types() {
    return {
      create: this.name + ".create",
      create_success: this.name + ".create.success",
      create_fail: this.name + ".create.fail",

      read: this.name + ".read",
      read_success: this.name + ".read.success",
      read_fail: this.name + ".read.fail",

      edit: this.name + ".edit",
      edit_success: this.name + ".edit.success",
      edit_fail: this.name + ".edit.fail",

      update: this.name + ".update",
      update_success: this.name + ".update.success",
      update_fail: this.name + ".update.fail",

      delete: this.name + ".delete",
      delete_success: this.name + ".delete.success",
      delete_fail: this.name + ".delete.fail",

      set: this.name + ".set",
      get: this.name + ".get",

      deleteList: this.name + ".list.delete",
      addList: this.name + ".list.add",
    };
  }

  // ACTIONS
  get actions() {
    return {
      create: (data) => ({ type: this.types.create, payload: { data } }),
      create_success: (data) => ({
        type: this.types.create_success,
        payload: { data },
      }),
      create_fail: (data) => ({
        type: this.types.create_fail,
        payload: { data },
      }),

      read: (data) => ({ type: this.types.read, payload: data }),
      read_success: (data) => ({
        type: this.types.read_success,
        payload: { data },
      }),
      read_fail: (data) => ({ type: this.types.read_fail, payload: { data } }),

      update: (data) => ({ type: this.types.update, payload: { data } }),
      update_success: (data) => ({
        type: this.types.update_success,
        payload: { data },
      }),
      update_fail: (data) => ({
        type: this.types.update_fail,
        payload: { data },
      }),

      edit: (data) => ({ type: this.types.edit, payload: { data } }),
      edit_success: (data) => ({
        type: this.types.edit_success,
        payload: { data },
      }),
      edit_fail: (data) => ({ type: this.types.edit_fail, payload: { data } }),

      delete: (data) => ({ type: this.types.delete, payload: data }),
      delete_success: (data) => ({
        type: this.types.delete_success,
        payload: { data },
      }),
      delete_fail: (data) => ({
        type: this.types.delete_fail,
        payload: { data },
      }),

      set: (data) => ({ type: this.types.set, payload: { data } }),
      get: (data) => ({ type: this.types.get, payload: { data } }),

      addList: (data) => ({ type: this.types.addList, payload: { data } }),
      deleteList: (data) => ({
        type: this.types.deleteList,
        payload: { data },
      }),
    };
  }

  // REDUCERS
  get reducers() {
    const initState = {
      list: [],
      form: {},
      actions: {
        type: null,
        ok: false,
      },
      alert: "",
    };

    const reducer = (state = initState, action = {}) => {
      let newState = {};
      switch (action.type) {
        case this.types.read_success:
          var { data, success } = action.payload.data;
          if (success) {
            newState = {
              actions: "read_success",
              success,
              list: data,
            };
          } else {
            newState = {
              actions: "read_fail",
              success,
              alert: data,
            };
          }

          return {
            ...state,
            ...newState,
          };

        case this.types.delete_success:
          var { data, success } = action.payload.data;
          newState = {
            actions: "delete_success",
            success,
          };

          return {
            ...state,
            ...newState,
          };

        case this.types.edit:
          var { id } = action.payload.data;
          var { list } = state;
          var form = list ? list.find((item) => item.id === id) : false;
          newState = {
            actions: "edit",
            success: !!form,
            form,
          };

          return {
            ...state,
            ...newState,
          };

        case this.types.create_success:
          var { data, success } = action.payload.data;
          console.log("Data received in create_success", data);
          newState = {
            actions: success ? "create_success" : "create_fail",
            success,
            form: data,
            create: action.payload,
          };

          return {
            ...state,
            ...newState,
          };

        case this.types.update_success || this.types.delete_success:
          var { data, success } = action.payload.data;
          newState = {
            actions: success ? "update_success" : "update_fail",
            success,
            form: data,
          };
          return {
            ...state,
            ...newState,
          };

        case this.types.set:
          var newForm = action.payload.data;
          var { form } = state;
          return {
            ...state,
            ...form,
            ...{ form: newForm },
          };

        case this.types.addList:
          var { data } = action.payload;
          var { list } = state;
          list.push({
            id: shortid.generate(),
            type: data.type,
            message: data.message,
          });
          return {
            ...state,
            list,
          };

        case this.types.deleteList:
          var { data } = action.payload;
          var { list } = state;
          list = list.filter((item) => item.id !== data.id);
          return {
            ...state,
            list,
          };
        default:
          return state;
      }
    };

    return reducer;
  }

  // SAGAS
  get sagas() {
    const $this = this; // new Model('show');

    const create = function* (action) {
      try {
        const data = yield call($this.api.create, {
          formData: action.payload.data.formData
            ? action.payload.data.formData
            : action.payload.data,
          action: (d) =>
            action.payload.data.action ? action.payload.data.action(d) : null,
        });
        if (true || (data && Array.isArray(Object.keys(data)))) {
          yield put($this.actions.create_success(data));
          // yield put($this.actions.read());
        } else {
          yield put($this.actions.create_fail(data));
        }
      } catch (err) {
        yield put($this.actions.create_fail(err));
      }
    };

    const read = function* (action) {
      try {
        const data = yield call($this.api.read, action.payload);
        if (true || Array.isArray(data)) {
          yield put($this.actions.read_success(data));
        } else {
          yield put($this.actions.read_fail(data));
        }
      } catch (err) {
        yield put($this.actions.read_fail(err));
      }
    };

    const update = function* (action) {
      try {
        const data = yield call($this.api.update, {
          formData: action.payload.data.formData
            ? action.payload.data.formData
            : action.payload.data,
          action: (d) =>
            action.payload.data.action ? action.payload.data.action(d) : null,
        });
        if (data && Array.isArray(Object.keys(data))) {
          yield put($this.actions.update_success(data));
          // yield put($this.actions.read());
        } else {
          yield put($this.actions.update_fail(data));
        }
      } catch (err) {
        yield put($this.actions.update_fail(err));
      }
    };

    const deleted = function* (action) {
      try {
        // IF reset
        if (action.payload.data === "reset") {
          yield put($this.actions.delete_success(action.payload.data));
        } else {
          const data = yield call($this.api.delete, action.payload);
          if (true || Array.isArray(Object.keys(data))) {
            yield put($this.actions.delete_success(data));
            // yield put($this.actions.read());
          } else {
            yield put($this.actions.delete_fail(data));
          }
        }
      } catch (err) {
        yield put($this.actions.delete_fail(err));
      }
    };

    return {
      create,
      read,
      update,
      deleted,
    };
  }

  async idbData() {
    const database = new Database();
    const db = await database.connect(IDB_NAME);
    const d = await db.read(this.name);
    const response = Array.isArray(d) ? d[0] : d;
    console.log("DATA for ", this.name, response);
    return response;
  }

  // API
  get api() {
    const database = new Database();

    return {
      read: (data) =>
        axios
          .get(data.id ? this.server + `/${data.id}` : this.server)
          .then((res) => {
            if (!data.id) {
              database
                .connect(IDB_NAME)
                .then((db) => db.create(this.name, res.data));
            }
            return res.data;
          })
          .catch(async (error) => {
            throw new Error(error);
            // return this.idbData();
          }),
      create: (data) => {
        const config = {
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            data.action && data.action(percentCompleted);
          },
        };
        return axios
          .post(this.server, data.formData, config)
          .then((res) => {
            console.log("CREATE ", res.data);
            return res.data;
          })
          .catch(async (error) => {
            console.log("API error ", error);
            throw new Error(error);
            let response = await this.idbData();
            const d = data.formData[Object.keys(data.formData)[0]];
            d.id = shortid.generate();

            if (response && response.data) {
              response.data.push(d);
            } else {
              response = {
                success: true,
                data: [d],
              };
            }
            database
              .connect(IDB_NAME)
              .then((db) => db.update(this.name, response));
            console.log("DATA create", response);
            window.location.reload(true);
            // throw new Error(error);
            // return response;
          });
      },
      delete: (data) =>
        axios
          .delete(this.server + "/" + data.id)
          .then((res) => {
            return res.data;
          })
          .catch(async (error) => {
            throw new Error(error);
            const response = await this.idbData();
            if (response && response.data) {
              response.data = response.data.filter(
                (item) => item.id !== data.id
              );
              database
                .connect(IDB_NAME)
                .then((db) => db.update(this.name, response));
            }
            window.location.reload(true);
          }),
      update: (data) => {
        const formData = data.formData ? data.formData : data;
        const config = {
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            data && data.action(percentCompleted);
          },
        };
        return axios
          .put(this.server + "/" + formData.id, formData, config)
          .then((res) => {
            return res.data;
          })
          .catch(async (error) => {
            throw new Error(error);
            const response = await this.idbData();
            if (response && response.data) {
              const index = response.data.findIndex(
                (item) => (item.id = formData.id)
              );
              if (index !== -1) {
                response.data[index] = formData[Object.keys(formData)[0]];
                database
                  .connect(IDB_NAME)
                  .then((db) => db.update(this.name, response));
              }
            }
            window.location.reload(true);
          });
      },
    };
  }

  // Validation
}

export default Model;
