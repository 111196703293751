const apiServer = process.env.REACT_APP_serverIp
  ? process.env.REACT_APP_serverIp + ":" + process.env.REACT_APP_PORT
  : "";
console.log("ENV:", apiServer);

const statuses = {
  NEW: 1,
  FINISHED: 2,
  WAIT_CUSTOMER: 3,
  WAIT_PARTS: 4,
};

const settings = {
  token: {
    service_number_prefix: "A",
    telephone: "0709496986",
    email: "faizfix@hotmail.com",
    organization_number: "840812-5881",
    notice: `2Specialister på att laga, serva och reparera mobiltelfoner surplattor och Datorer, Våra ledord är service snabbhet och kvalitet.
        Med 6 månaders garanti!`,
  },
  opening_hours: {
    monday_friday: ["10:00", "18:00"],
    saturday: ["10:00", "14:00"],
    sunday: ["STÄNGT"],
  },
  tables: {
    rows_count: 10,
    data_year: 2020,
  },
  page_setup: {
    token: {
      dimensions: {
        width: 70,
        height: 200,
      },
      margins: {
        top: 10,
        right: 5,
        bottom: 10,
        left: 5,
      },
    },
    opinion: {
      dimensions: {
        width: 21,
        height: 29,
      },
      margins: {
        top: 30,
        right: 45,
        bottom: 30,
        left: 45,
      },
    },
  },
};

export { apiServer, statuses, settings };
