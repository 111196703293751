import React from "react";

const ProductList = (props) => {
  let list = props.product.list;
  if (props.make.active) {
    list = list.filter((l) => l.make_id === props.make.active);
  }
  return (
    <ul
      className="list-group list-group-flush"
      style={{
        height: "216px",
        overflow: "auto",
        border: "1px solid lightgrey",
        padding: "5px",
      }}
    >
      {list &&
        list.map((p, i) => (
          <li key={i} className="list-group-item list-group-item-action">
            <a
              onClick={(e) => {
                e.preventDefault();
                props.setActive(p.id, "product", p);
              }}
              href=""
              className={
                p.id === props.product.active ? "d-block active" : "d-block"
              }
            >
              {p.name}
              <button
                onClick={() => props.onDelete(p.id, "product")}
                className="p-1 btn btn-outline-light float-right"
              >
                <svg
                  width="1.5em"
                  height="1.5em"
                  viewBox="0 0 16 16"
                  className="bi bi-x-circle text-danger"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
                  />
                </svg>
              </button>
            </a>
          </li>
        ))}
    </ul>
  );
};

export default ProductList;
