import React, {Component} from 'react';
import {Route, Switch, BrowserRouter} from 'react-router-dom';


import Ni from './components/Ni';
import Login from './components/Login';
import ReceiptForm from './components/receipt/Form';
import OrderList from './components/order/OrderList';
import CrudForm from './components/settings/crud/CrudForm';
import User from './components/user/Form';
import PrivateRoute from './components/PrivateRoute';
import Opinion from "./components/print/Opinion";
import Backup from "./components/backup/Backup";

class App extends Component {

    render() {
        return (
            <BrowserRouter basename="/">
                <Switch>
                    <Route exact path={`/`} component={Login}/>
                    <Route exact path={`/opinion`} component={Opinion}/>
                    <Route exact path={`/login`} component={Login}/>
                    <Route exact path={`/settings`} component={PrivateRoute(CrudForm)}/>
                    <Route exact path={`/user`} component={PrivateRoute(User)}/>
                    <Route exact path={`/receipt`} component={PrivateRoute(ReceiptForm)}/>
                    <Route exact path={`/receipt/:id`} component={PrivateRoute(ReceiptForm)}/>
                    <Route exact path={`/order`} component={PrivateRoute(OrderList)}/>
                    <Route exact path={`/backup`} component={PrivateRoute(Backup)}/>
                    <Route component={Ni}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
