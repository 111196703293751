import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import models from "../../../store/models";
import Dashboard from "../../../containers/Dashboard";
import Make from "../make";
import MakeList from "../make/MakeList";
import ProductList from "../product/ProductList";
import Product from "../product";
import Service from "../service";
import ServiceList from "../service/ServiceList";
import Extra from "../extra";
import ExtraList from "../extra/ExtraList";

import Alert from "../../Alert";
import General from "../General";

class CrudForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      make: {
        form: {
          name: "",
        },
        active: "",
        list: [],
        errors: [],
        buttonLabel: "Lägga till",
      },
      product: {
        form: {
          name: "",
          make_id: "",
          active: "",
        },
        list: [],
        errors: [],
        buttonLabel: "Lägga till",
      },
      service: {
        form: {
          name: "",
          product_id: "",
          active: "",
          qty: 0,
          price: 0,
        },
        list: [],
        errors: [],
        buttonLabel: "Lägga till",
      },
      extra: {
        form: {
          name: "",
          product_id: "",
          active: "",
          qty: 0,
          price: '',
        },
        list: [],
        errors: [],
        buttonLabel: "Lägga till",
      },
      errors: false,
    };
  }

  // CRUD
  create = (collection) => {
    // e.preventDefault();
    switch (collection) {
      case "make":
        // create or update
        if (this.state.make.form._id) {
          this.props.updateActionMake({
            id: this.state.make.form._id,
            make: this.state.make.form,
          });
          setTimeout(() => {
            this.props.readActionMake({});
            this.clearForm("make");
          }, 1000);
        } else {
          this.props.createActionMake({
            make: this.state.make.form,
          });
        }
        break;
      case "product":
        const { form } = this.state.product;
        form.make_id = this.state.make.active;
        this.props.createActionProduct({ product: form });

        // create or update
        if (form._id) {
          this.props.updateActionProduct({
            id: form._id,
            product: form,
          });
          setTimeout(() => {
            this.props.readActionProduct({});
            this.clearForm("product");
          }, 1000);
        } else {
          this.props.createActionProduct({ product: form });
        }
        // setTimeout(() => this.props.readActionProduct({}) && this.clearForm('product'), 100, this);
        break;
      case "service":
        const serviceForm = this.state.service.form;
        serviceForm.product_id = this.state.product.active;
        // create or update
        if (serviceForm._id) {
          this.props.updateActionService({
            id: serviceForm._id,
            service: serviceForm,
          });
          setTimeout(() => {
            this.props.readActionService({});
            this.clearForm("service");
          }, 1000);
        } else {
          this.props.createActionService({
            service: serviceForm,
          });
        }
        break;

      case "extra":
        const extraForm = this.state.extra.form;
        // create or update
        if (extraForm._id) {
          this.props.updateActionExtra({
            id: extraForm._id,
            extra: extraForm,
          });
          setTimeout(() => {
            this.props.readActionExtra({});
            this.clearForm("extra");
          }, 1000);
        } else {
          this.props.createActionExtra({
            extra: {name:extraForm.name, price:extraForm.price},
        });
      }
        // setTimeout(() => this.props.readActionService({}) && this.clearForm('service'), 100, this);
      break;
    }
  };
  read = (collection) => {
    switch (collection) {
      case "make":
      case "product":
      case "service":
      case "extra":
    }
  };
  update = (collection) => {
    switch (collection) {
      case "make":
      case "product":
      case "service":
      case "extra":
    }
  };
  delete = (id, collection) => {
    switch (collection) {
      case "make":
        this.props.deleteActionMake({ id });
        setTimeout(() => this.props.readActionMake({}), 100, this);
        break;
      case "product":
        this.props.deleteActionProduct({ id });
        setTimeout(() => this.props.readActionProduct({}), 100, this);
        break;
      case "service":
        this.props.deleteActionService({ id });
        setTimeout(
          () => {
            this.props.readActionService({});
            this.clearForm("service");
          },
          100,
          this
        );
        break;
      case "extra":
        this.props.deleteActionExtra({ id });
        setTimeout(
          () => {
            this.props.readActionExtra({});
            this.clearForm("extra");
          },
          100,
          this
        );
        break;
    }
  };

  clearForm = (collection) => {
    const item = this.state[collection];
    console.log("clearing form for ", collection);
    item.form.name = "";
    if (true || collection === "service") {
      item.form._id = "";
      item.form.price = "";
      item.form.qty = "";
    }
    item.buttonLabel = "Lägga till";
    this.setState({ collection: item });
  };

  onChange = (e, collection) => {
    const item = this.state[collection];
    item.form[e.target.id] = e.target.value;
    this.setState({ collection: item });
  };

  onKeyDown = (e, collection) => {
    if (e.key === "Enter") {
      this.create(collection);
    }
  };

  setActive = (id, collection, p = false) => {
    const item = this.state[collection];
    item.buttonLabel = "Lägga till";
    item.active = id;
    if (p) {
      item.form = { ...item.form, ...p };
      item.buttonLabel = "Spara";
    }
    this.setState({ [collection]: item });
  };

  componentDidMount() {
    console.log("componentDidMount");
    this.setLists(this.props);
  }

  setLists = (props) => {
    const { makes, products, services, extras } = props;
    const { make, product, service, extra } = this.state;
    let errors = {};

    switch (makes.actions) {
      case "read_success":
        make.list = makes.list;
        this.setState({ make });
        break;
      case "create_success":
        errors = {
          type: "alert-success",
          header: "Success",
          errors: [{ Saved: { message: "Saved successfully !" } }],
          done: true,
        };
        make.list = makes.list;
        this.setState({ make, errors });
        this.props.readActionMake({});
        this.clearForm("make");
        break;
      case "delete_success":
        errors = {
          type: "alert-info",
          header: "Success",
          errors: [{ Saved: { message: "Deleted successfully !" } }],
          done: true,
        };
        this.setState({ errors });
        break;
      case "create_fail":
      case "delete_fail":
        errors = {
          type: "alert-danger",
          header: "Errors",
          errors: makes.form,
        };
        this.setState({ errors });
        break;
    }

    switch (products.actions) {
      case "read_success":
        product.list = products.list;
        this.setState({ product });
        break;
      case "create_success":
        errors = {
          type: "alert-success",
          header: "Success",
          errors: [{ Saved: { message: "Saved successfully !" } }],
          done: true,
        };
        product.list = products.list;
        this.setState({ product, errors });
        this.props.readActionProduct({});
        this.clearForm("product");
        break;
      case "delete_success":
        errors = {
          type: "alert-info",
          header: "Success",
          errors: [{ Saved: { message: "Deleted successfully !" } }],
          done: true,
        };
        this.setState({ errors });
        break;
      case "create_fail":
      case "delete_fail":
        errors = {
          type: "alert-danger",
          header: "Errors",
          errors: products.form,
        };
        this.setState({ errors });
        break;
    }

    switch (services.actions) {
      case "read_success":
        service.list = services.list;
        this.setState({ service });
        break;
      case "create_success":
        errors = {
          type: "alert-success",
          header: "Success",
          errors: [{ Saved: { message: "Saved successfully !" } }],
          done: true,
        };
        service.list = services.list;
        this.setState({ service, errors });
        this.props.readActionService({});
        this.clearForm("service");
        break;
      case "delete_success":
        errors = {
          type: "alert-info",
          header: "Success",
          errors: [{ Saved: { message: "Deleted successfully !" } }],
          done: true,
        };
        this.setState({ errors });
        break;
      case "create_fail":
      case "delete_fail":
        errors = {
          type: "alert-danger",
          header: "Errors",
          errors: services.form,
        };
        this.setState({ errors });
        break;
    }


    switch (extras.actions) {
      case "read_success":
        extra.list = extras.list;
        this.setState({ extra });
        break;
      case "create_success":
        errors = {
          type: "alert-success",
          header: "Success",
          errors: [{ Saved: { message: "Saved successfully !" } }],
          done: true,
        };
        extra.list = extras.list;
        this.setState({ extra, errors });
        this.props.readActionExtra({});
        this.clearForm("extra");
        break;
      case "delete_success":
        errors = {
          type: "alert-info",
          header: "Success",
          errors: [{ Saved: { message: "Deleted successfully !" } }],
          done: true,
        };
        this.setState({ errors });
        break;
      case "create_fail":
      case "delete_fail":
        errors = {
          type: "alert-danger",
          header: "Errors",
          errors: extras.form,
        };
        this.setState({ errors });
        break;
    }

    setTimeout(() => this.setState({ errors: false }), 2000);
  };

  componentWillReceiveProps(nextProps, nextContext) {
    this.setLists(nextProps);
  }

  render() {
    return (
      <Dashboard>
        <div className="container my-md-5">
          <div className="row">
            <div className="col-md-12">
              <Alert errors={this.state.errors} />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <h3>Services</h3>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-4 mt-2">
              <Make
                make={this.state.make}
                onKeyDown={this.onKeyDown}
                onChange={this.onChange}
                onCreate={this.create}
              />
              <MakeList
                onDelete={this.delete}
                make={this.state.make}
                setActive={this.setActive}
              />
            </div>
            <div className="col-md-4 mt-2">
              <Product
                product={this.state.product}
                onKeyDown={this.onKeyDown}
                onChange={this.onChange}
                onCreate={this.create}
              />
              <ProductList
                onDelete={this.delete}
                product={this.state.product}
                make={this.state.make}
                setActive={this.setActive}
              />
            </div>
            <div className="col-md-4 mt-2">
              <Service
                service={this.state.service}
                onKeyDown={this.onKeyDown}
                onChange={this.onChange}
                onCreate={this.create}
              />
              <ServiceList
                onDelete={this.delete}
                service={this.state.service}
                product={this.state.product}
                setActive={this.setActive}
              />
            </div>
          </div>


          <div className="row">
            <div className="col">
              <h3>Extra</h3>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-4 mt-2">
            <Extra
                extra={this.state.extra}
                onKeyDown={this.onKeyDown}
                onChange={this.onChange}
                onCreate={this.create}
              />
              <ExtraList
                onDelete={this.delete}
                extra={this.state.extra}
                product={this.state.product}
                setActive={this.setActive}
              />
            </div>
          </div>

          <div className="row">
            <General />
          </div>
        </div>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  makes: state.makes,
  products: state.products,
  services: state.services,
  extras: state.extras,
});

const mapActionsToProps = {
  deleteActionMake: models.makes.actions.delete,
  deleteActionProduct: models.products.actions.delete,
  deleteActionService: models.services.actions.delete,
  deleteActionExtra: models.extras.actions.delete,

  createActionMake: models.makes.actions.create,
  createActionProduct: models.products.actions.create,
  createActionService: models.services.actions.create,
  createActionExtra: models.extras.actions.create,

  updateActionMake: models.makes.actions.update,
  updateActionProduct: models.products.actions.update,
  updateActionService: models.services.actions.update,
  updateActionExtra: models.extras.actions.update,

  readActionMake: models.makes.actions.read,
  readActionProduct: models.products.actions.read,
  readActionService: models.services.actions.read,
  readActionExtra: models.extras.actions.read,
};

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(CrudForm)
);
