import ActiveRecord from './base/ActiveRecord';

class Toast extends ActiveRecord {

    constructor(name) {
        super(name);
        this.form = {
            name: ''
        };
    }
}

export default Toast;
