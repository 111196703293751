import ActiveRecord from './base/ActiveRecord';

class Order extends ActiveRecord {

    constructor(name) {
        super(name);
        this.form = {
        };
    }
}

export default Order;
