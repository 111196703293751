import React from "react";
import DateTime from "./DateTime";
import User from "./User";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: "0px",
      display: "none",
    };
  }

  collapse = (e) => {
    e.preventDefault();
    let { width, display } = this.state;
    if (width === "0px") {
      width = "100px";
      display = "block";
    } else {
      width = "0px";
      display = "none";
    }
    this.setState({ width });
    document.getElementById("drawerMobileSelf").style.width = width;
    document.getElementById("drawerMobileSelf").style.display = display;
  };

  render() {
    return (
      <nav
        className="navbar navbar-expand-md navbar-default fixed-top"
        style={{ borderBottom: "4px solid lightcoral" }}
      >
        <a
          style={{ backgroundColor: "transparent" }}
          className="navbar-brand col-md-1 col-lg-1 d-md-block collapse"
          href="/"
        >
          <img
            style={{ height: "2em" }}
            src="/images/logo-square.png"
            alt="FF"
          />
        </a>
        <div className="container-fluid">
          <button
            onClick={this.collapse}
            style={{ padding: 0, margin: 0 }}
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#drawerMobile"
            aria-controls="drawerMobile"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span
              style={{ height: "48px", width: "48px" }}
              className="text-left navbar-toggler-icon"
            >
              <img
                src="/images/favicon.png"
                alt=""
                style={{ height: "100%" }}
              />
            </span>
          </button>

          <div className="collapse navbar-collapse">
            <ul className="row col justify-content-center navbar-nav mr-auto mb-2 mb-md-0">
              <li className="col nav-item active">
                <DateTime />
              </li>
              <li className="col text-center">
                <a
                  className="navbar-brand"
                  style={{ backgroundColor: "transparent" }}
                  href="/"
                >
                  <img
                    style={{ width: "6em", height: 'auto' }}
                    src="/images/logo.png"
                    alt="FF"
                  />
                </a>
              </li>
              <li className="col pt-2 text-right">
                <User />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
