class Product {
    id = '';
    name = '';
    make_id = '';
    list = [];
    loaded = false;
    _filter = [];

    constructor(products = false) {
        this.setList(products.list);
    }

    load(model) {
        this.id = model.form.product?.id;
        this.name = model.form.product?.name;
        this.make_id = model.form.product?.make_id;
    }

    setList(list) {
        if (list && list.length > 0 && this.loaded === false) {
            this.list = this._filter = list;
            this.loaded = true;
        }
    }

    clear() {
        this.id = '';
        this.name = '';
        this.make_id = '';
    }

    get options() {
        return this._filter.map(product => ({value: product.id, label: product.name, make_id: product.make_id}));
    }

    set filter(make) {
        this.clear();
        this._filter = this.list.filter(product => product.make_id === make.id);
    }

    get selectedOption() {
        return {value: this.id, label: this.name, make_id: this.make_id};
    }

    set selectedOption(option) {
        this.id = option.value;
        this.name = option.label;
        this.make_id = option.make_id;
    }

    get form() {
        return {id: this.id, name: this.name, make_id: this.make_id};
    }
}

export default Product;
