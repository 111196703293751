import React from "react";
import Barcode from "react-barcode";
import SellPaid from "./SellPaid";
import SellUnpaid from "./SellUnpaid";
import Opening from "./Opening";
import AttFixa from "./AttFixa";
import ShopUnpaid from "./ShopUnpaid";
import AttShop from "./AttShop";

class Shop extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log("componentDidMount PrintPreview");
    const myModalEl = document.getElementById("modelPrintPreview");
    if (myModalEl) {
      myModalEl.addEventListener("shown.bs.modal", function (e) {
        // document.getElementById('start-print').click();
        console.log("Sum inside pre");
      });
    }
  }

  componentWillReceiveProps(nextProps, context) {
    console.log("Settings", nextProps);
  }

  render() {
    const { model, settings } = this.props;
    let style = {};
    if (model.settings.page_setup?.token?.dimensions) {
      const { dimensions, margins } = model.settings.page_setup.token;
      style = {
        width: dimensions.width + "mm",
        height: dimensions.height + "mm",
        margins: `${margins.top}mm ${margins.left}mm ${margins.bottom}mm ${margins.left}mm`,
      };
    }
    let garanti_count = "";
    if (model.form.garanti_ingen) {
      garanti_count = "Med ingen garanti!";
    }
    if (model.form.garanti_3_months) {
      garanti_count = "Med 3 månader garanti...utan Skada!";
    }
    if (model.form.garanti_6_months) {
      garanti_count = "Med 6 månader garanti...utan Skada!";
    }
    if (model.form.garanti_1_year) {
      garanti_count = "Med 1 år garanti...utan Skada!";
    }

    return (
      <div className="print-receipt">
        <div className="row p-0">
          <div className="col">
            <div
              className="py-2 px-3 text-center"
              style={{ border: "3px solid black", width: "100%" }}
            >
              <h1 style={{ margin: "10px" }}>
                <img
                  style={{ width: "100%" }}
                  src="/images/logo-black-n-white.jpeg"
                  alt="Faiz Fix"
                />
              </h1>
              <p className="text-left px-0 m-0">
                <strong>Tel :</strong> {model.settings.token?.telephone}
              </p>
              <p className="text-left px-0 m-0">
                {model.settings.token?.email} / Org nr
                {" " + model.settings.token?.organization_number}
              </p>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingRight: "0.2rem" }}>
          <div className="col-12 receipt-body">
            {model.form.customer_picked ? (
              <SellPaid model={model} />
            ) : (
              <ShopUnpaid model={model} />
            )}

            <AttShop model={model} title='Köp' />
          </div>
  
          <div className="col-12">
            <div className="notice text-center">
              
              <Barcode
                height={30}
                value={
                  model.form.service_number
                    ? model.form.service_number
                    : "000000"
                }
              />
            </div>
          </div>
        </div>
        {settings.token.short_kvitto && (
          <p style={{ pageBreakBefore: "always" }}></p>
        )}

    
      </div>
    );
  }
}

export default Shop;
