import React from "react";

const Extra = (props) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="input-group">
          <input
            value={props.extra.form.name}
            onKeyDown={(e) => props.onKeyDown(e, "extra")}
            onChange={(e) => props.onChange(e, "extra")}
            id="name"
            autoComplete="0"
            type="text"
            className="form-control"
            placeholder="Tjänst"
          />
          <input
            value={props.extra.form.price}
            onKeyDown={(e) => props.onKeyDown(e, "extra")}
            onChange={(e) => props.onChange(e, "extra")}
            id="price"
            type="text"
            className="form-control"
            placeholder="Pris"
          />
          <div className="input-group-append">
            <button
              onClick={() => props.onCreate("extra")}
              type="submit"
              className="btn btn-primary p-2"
            >
              {props.extra.buttonLabel}
            </button>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="list-group mt-3"></div>
      </div>
    </div>
  );
};

export default Extra;
