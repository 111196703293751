import React from "react";
import moment from "moment";

export default function SellPaid({ model }) {
  const dateCreated = (_id) => {
    if (_id) {
      const timeStamp = parseInt(_id.substr(0, 8), 16) * 1000;
      const date = new Date(timeStamp);
      return moment(timeStamp).format("YYYY-MM-DD HH:mm");
      // return date.getDay();
    }
    return "na";
  };
  return (
    <table className="table table-borderless table-responsive mb-0">
      <tbody>
        <tr>
          <th>Service Nr</th>
          <td>{model.form.service_number}</td>
        </tr>
        <tr>
          <th>Reg.datum</th>
          <td>{dateCreated(model.form._id)}</td>
        </tr>
        <tr>
          <th>Namn</th>
          <td>{model.form.name}</td>
        </tr>
        <tr>
          <th>Tel</th>
          <td>{model.form.telephone}</td>
        </tr>
        <tr>
          <th>Enhet</th>
          <td>{model.form.product.name}</td>
        </tr>

        <tr>
          <th>Excl.Moms</th>
          <td>{model.exclusive_moms}kr</td>
        </tr>

        <tr>
          <th>
            Moms<span className="small">(25%)</span>
          </th>
          <td>{model.moms_amount} kr</td>
        </tr>
        <tr>
          <th>Rabatt</th>
          <td>
            {model.form.discount} % ({model.discount_amount} kr)
          </td>
        </tr>
        <tr>
          <th>Belopp</th>
          <td>{model.form.discountedPrice}kr</td>
        </tr>
        <tr>
          <th>Hämta</th>
          <td>
            {model.form.date_picked} / {model.form.time_picked}{" "}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
