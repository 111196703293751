import React from 'react';
import ReactToPrint from 'react-to-print';


class Modal extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        console.log('componentDidMount PrintPreview');
        const myModalEl = document.getElementById('modelPrintPreview');
        const $this = this;
        myModalEl.addEventListener('shown.bs.modal', function (e) {
            // document.getElementById('start-print').click();
            // $this.props.calculateSum();
            console.log('Sum inside pre')
        });
    }

    render() {
        const className = "modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable " + this.props.size;

        return (
            <div className="modal fade" id="modelPrintPreview" tabIndex="-1"
                 aria-labelledby="modelPrintPreviewLabel"
                 aria-hidden="true"
            >
                <div className={className}>
                    <div className="modal-content">
                        <div className="modal-header shadow">
                            <h5 className="modal-title" id="modelPrintPreviewLabel">
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-newspaper mr-2"
                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M0 2A1.5 1.5 0 0 1 1.5.5h11A1.5 1.5 0 0 1 14 2v12a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 0 14V2zm1.5-.5A.5.5 0 0 0 1 2v12a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V2a.5.5 0 0 0-.5-.5h-11z"/>
                                    <path fillRule="evenodd"
                                          d="M15.5 3a.5.5 0 0 1 .5.5V14a1.5 1.5 0 0 1-1.5 1.5h-3v-1h3a.5.5 0 0 0 .5-.5V3.5a.5.5 0 0 1 .5-.5z"/>
                                    <path
                                        d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z"/>
                                </svg>
                                Skriv ut</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{maxHeight: 'calc(100vh - 200px)'}}>
                            <div className="container-fluid">
                                <div style={{border: '1px solid white'}} className="modal-body-print" ref={el => this.componentRef = el}>
                                    {
                                        this.props.children
                                    }
                                </div>
                                <br className="end-of-page"/>
                            </div>
                        </div>
                        <div className="modal-footer shadow-footer justify-content-center text-center">
                            <button type="button" className="btn btn-secondary pr-3" data-dismiss="modal">
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-x-square text-white-50 mr-2"
                                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                    <path fillRule="evenodd"
                                          d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"/>
                                    <path fillRule="evenodd"
                                          d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"/>
                                </svg>
                                Close</button>
                            <ReactToPrint
                                pageStyle="border:5px solid green"
                                trigger={() => <button id="start-print" type="button"
                                                       className="btn btn-primary pr-3">
                                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-printer text-white-50 mr-2"
                                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M11 2H5a1 1 0 0 0-1 1v2H3V3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h-1V3a1 1 0 0 0-1-1zm3 4H2a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h1v1H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z"/>
                                                        <path fillRule="evenodd"
                                                            d="M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM5 8a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H5z"/>
                                                        <path d="M3 7.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                                    </svg>
                                                Print</button>}
                                content={() => this.componentRef}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Modal;
