import { statuses } from "../../common/constants";

const state = () => ({
  form: {
    deal_title: "",
    deal: "sell",
    service_number: "",
    imei: "",
    name: "",
    telephone: "",
    code: "",
    technical_info: "",
    customer_picked: false,
    date_picked: new Date().format(),
    time_picked: new Date().timeFormat(),
    info: "",
    date: new Date().format(),
    time: new Date().timeFormat(),
    screen_cover: false,
    express: false,
    inspection: false,
    extras: [],
    garanti_ingen: false,
    garanti_3_months: false,
    garanti_6_months: false,
    garanti_1_year: false,
    price: 0,
    discountedPrice: "",
    discount: "",
    payment_method: "",
    status: statuses.NEW,
    sync_needed: false,
    make: {
      id: "",
      name: "",
    },
    product: {
      id: "",
      name: "",
      make_id: "",
    },

    fix: {
      list: [], // list of services selected
    },
  }, // \Form

  errors: null,
  times: [],
  isNewRecord: true,
  list: [],
});

export default state;
