import React from "react";

class Opinion extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { model } = this.props;

    return (
      <div className="print-opinion">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="border-info p-5">
              <div className="opinion">
                <div className="header">
                  <div className="row">
                    <div className="col">
                      <img
                        className="logo-hd"
                        src="/images/logo-colored.jpeg"
                        alt="Logo"
                      />
                    </div>
                    <div className="col text-right">
                      <h2>Utlåtande</h2>
                      <p>{new Date().format()}</p>
                    </div>
                  </div>
                </div>
                <div className="body p-4">
                  <div className="row">
                    <div className="col-4">
                      <strong>Service nr</strong>
                    </div>
                    <div className="col-8 text-left">
                      <p>{model.form.service_number}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <strong>Namn</strong>
                    </div>
                    <div className="col-8 text-left">
                      <p>{model.form.name}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <strong>Telefon nr</strong>
                    </div>
                    <div className="col-8 text-left">
                      <p>{model.form.telephone}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <strong>Enhet</strong>
                    </div>
                    <div className="col-8 text-left">
                      <p>{model.form.product.name}</p>
                    </div>
                  </div>
                  {model.form.imei && (
                    <div className="row">
                      <div className="col-4">
                        <strong>IMEI</strong>
                      </div>
                      <div className="col-8 text-left">
                        <p>{model.form.imei}</p>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-12 mt-5">
                      <p>
                        Vi har undersökt enheten och det går inte att repereras
                        på grund av följande anledningar:
                      </p>

                      <div>
                        {model.form.technical_info
                          .split("\n")
                          .map((para, i) => (
                            <p key={i}>{para}</p>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{ backgroundColor: "white", color: "black" }}
                  className="footer px-4"
                >
                  <div className="row">
                    <div className="col mt-5">
                      <h3>Mottagarens Underskrift</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-right">
                      <p>
                        <strong>Tel:</strong> {model.settings.token?.telephone}
                      </p>
                      <p>
                        <strong>Epost:</strong> {model.settings.token?.email}
                      </p>
                      <p>
                        <strong>Org nr:</strong>{" "}
                        {model.settings.token?.organization_number}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    );
  }
}

export default Opinion;
