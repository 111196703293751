import React from "react";
import { withRouter } from "react-router-dom";
import io from "socket.io-client";

import Dashboard from "../../containers/Dashboard";
import models from "../../store/models";
import { connect } from "react-redux";
import Table from "./Table";
import { padStart } from "lodash";
import Pagination from "../search/Pagination";
import { statuses, settings, apiServer } from "../../common/constants";

const stringSimilarity = require("string-similarity");

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: {
        service_number: "",
        name: "",
        telephone: "",
        list: [],
        errors: false,
      },
      status: statuses.NEW,
      activePage: 1,
      model: {},
      filterList: [],
      service_number: "",
    };
  }

  componentDidMount() {
    console.log("OrderList props componentDidMount", this.props);
    //this.props.readAction({});
    this.setLists(this.props);
    const socket = io(apiServer, {
      transports: ["websocket", "polling"],
    });

    socket.on("update", (data) => {
      console.log("Update", data);
      if (data.url && data.url.includes("/api/v1/orders")) {
        console.log("Update ORDERS  ");
        this.props.readAction({});
      }
    });
  }

  setLists = (props) => {
    const { orders, settings } = props;
    console.log("OrderList props", props);
    if (orders && orders.success && orders.list && orders.list.length > 0) {
      const { order } = this.state;
      order.list = orders.list;
      this.setState({ order, settings });
      this.filterBy(null, this.state.status);
    } else {
      this.setState({ settings });
    }
  };

  filterBy = (e, status) => {
    console.log("filterBy ", status);
    e && e.preventDefault();
    const { order } = this.state;
    let filterList = [];
    if (status === 0) {
      filterList = order.list;
    } else {
      filterList = order.list.filter((item) => item.status === status);
      console.log("else ", status, filterList);
    }
    this.setState({ filterList, status });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    this.setLists(nextProps);
  }

  onDelete = (id) => {
    this.props.deleteAction({ id });
    this.props.readAction({});
  };

  onChange = (e) => {
    e.preventDefault();
    const LENGTH = 6;
    let { order, service_number } = this.state;
    service_number = "";
    order.service_number = "";
    order.name = "";
    order.telephone = "";
    if (e.target.id === "service_number" && e.target.value !== "") {
      const num = Number(e.target.value);
      service_number = padStart(num, LENGTH, "0");
      order[e.target.id] = "A" + service_number;
    } else {
      order[e.target.id] = e.target.value;
    }
    console.log(order);
    this.setState({ order, service_number });
  };

  onFocus = (e) => {
    e.preventDefault();
    e.target.select();
  };

  setActivePage = (activePage) => {
    this.setState({ activePage });
  };

  onFinish = (e, id) => {
    e.preventDefault();
    this.props.updateAction({ id, order: { status: statuses.FINISHED } });
    this.props.readAction({});
  };

  render() {
    let { order, filterList, settings } = this.state;
    let list = filterList;
    if (order.service_number.length > 2) {
      list = filterList.map((item) => {
        if (item && item.service_number && order && order.service_number) {
          item.similarity = stringSimilarity.compareTwoStrings(
            item.service_number,
            order.service_number
          );
        } else {
          item.similarity = 20;
        }
        return item;
      });
      list = list.sort((a, b) => b.similarity - a.similarity);
    } else if (order.name.length > 0) {
      list = filterList.map((item) => {
        if (item && item.name && order && order.name) {
          item.similarity = stringSimilarity.compareTwoStrings(
            item.name,
            order.name
          );
        } else {
          item.similarity = 20;
        }
        return item;
      });
      list = list.sort((a, b) => b.similarity - a.similarity);
    } else if (order.telephone.length > 2) {
      list = filterList.map((item) => {
        if (item && item.telephone && order && order.telephone) {
          item.similarity = stringSimilarity.compareTwoStrings(
            item.telephone,
            order.telephone
          );
        } else {
          item.similarity = 20;
        }
        return item;
      });
      list = list.sort((a, b) => b.similarity - a.similarity);
    }

    if (!settings) return null;

    return (
      <Dashboard>
        <div className="container-fluid mt-3">
          {/* <!-- 2-4-1-3-2 -->*/}
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="service_number" className="col-form-label">
                Service Nr{" "}
              </label>
              <div className="input-group">
                <span className="input-group-text">A</span>
                <input
                  type="text"
                  className="form-control"
                  id="service_number"
                  placeholder="Enter service number"
                  value={this.state.service_number}
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  onMouseEnter={this.onFocus}
                  autoComplete="off"
                  required
                />
                <div className="invalid-feedback">
                  Your username is required.
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <label htmlFor="date" className="col-form-label">
                Namn
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Skriv namn"
                value={this.state.order.name}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onMouseEnter={this.onFocus}
                autoComplete="off"
                required
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="date" className="col-form-label">
                Telefon
              </label>
              <input
                type="text"
                className="form-control"
                id="telephone"
                placeholder="Skriv telefon"
                value={this.state.order.telephone}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onMouseEnter={this.onFocus}
                autoComplete="off"
                required
              />
            </div>
          </div>

          <div className="row" style={{ minHeight: "calc(100vh - 250px)" }}>
            <div className="col-md-12 mb-5 mt-3 table-responsive">
              <Table
                onFinish={this.onFinish}
                onDelete={this.onDelete}
                table={{
                  cols: [
                    "Service Nr",
                    "Tid",
                    "Namn",
                    "Enhet",
                    "Fel",
                    "Hämta",
                    "Status",
                    "Telfon Nu",
                  ],
                  fields: [
                    "service_number",
                    "time",
                    "name",
                    "product",
                    "fix",
                    "date_picked",
                    "status",
                    "telephone",
                  ],
                  rows: list.slice(
                    settings.tables.rows_count * (this.state.activePage - 1),
                    this.state.activePage * settings.tables.rows_count
                  ),
                }}
                push={this.props.history.push}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Pagination
                items_per_page={settings.tables.rows_count}
                total_items={list.length}
                setActivePage={this.setActivePage}
              />
            </div>
            <div className="col-md-6 text-right">
              <div className="btn-group pagination">
                <input
                  tabIndex=""
                  type="button"
                  className="btn-check"
                  name="options-outlined"
                  id="success-outlined-new"
                />
                <label
                  onClick={(e) => this.filterBy(e, statuses.NEW)}
                  className={
                    "btn btn-outline-success px-5" +
                    (this.state.status === statuses.NEW ? " active" : "")
                  }
                  htmlFor="success-outlined-new"
                >
                  NEW
                </label>

                <input
                  tabIndex=""
                  type="button"
                  className="btn-check"
                  name="options-outlined"
                  id="danger-outlined-finished"
                  autoComplete="off"
                />
                <label
                  onClick={(e) => this.filterBy(e, statuses.FINISHED)}
                  className={
                    "btn btn-outline-danger px-5" +
                    (this.state.status === statuses.FINISHED ? " active" : "")
                  }
                  htmlFor="danger-outlined-finished"
                >
                  FINISHED
                </label>

                <input
                  tabIndex=""
                  type="button"
                  className="btn-check"
                  name="options-outlined"
                  id="info-outlined-unfinish"
                  autoComplete="off"
                />
                <label
                  onClick={(e) => this.filterBy(e, statuses.WAIT_CUSTOMER)}
                  className={
                    "btn btn-outline-info px-5" +
                    (this.state.status === statuses.WAIT_CUSTOMER
                      ? " active"
                      : "")
                  }
                  htmlFor="info-outlined-unfinish"
                >
                  VÄNTA KUND
                </label>

                <input
                  tabIndex=""
                  type="button"
                  className="btn-check"
                  name="options-outlined"
                  id="info-outlined-unfinish"
                  autoComplete="off"
                />
                <label
                  onClick={(e) => this.filterBy(e, statuses.WAIT_PARTS)}
                  className={
                    "btn btn-outline-info px-5" +
                    (this.state.status === statuses.WAIT_PARTS ? " active" : "")
                  }
                  htmlFor="info-outlined-unfinish"
                >
                  VÄNTA DELAR
                </label>

                <input
                  tabIndex=""
                  type="button"
                  className="btn-check"
                  name="options-outlined"
                  id="warning-outlined-all"
                  autoComplete="off"
                />
                <label
                  onClick={(e) => this.filterBy(e, 0)}
                  className={
                    "btn btn-outline-warning px-5" +
                    (this.state.status === 0 ? " active" : "")
                  }
                  htmlFor="warning-outlined-all"
                >
                  ALLA
                </label>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.orders,
  settings: state.settings.list[0],
});

const mapActionsToProps = {
  readAction: models.orders.actions.read,
  deleteAction: models.orders.actions.delete,
  updateAction: models.orders.actions.update,
};

OrderList.defaultProps = {
  settings: settings,
};

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(OrderList)
);
