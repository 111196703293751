import React from 'react';


const AdminLi = props => {
    const {form} = props.props;
    if (form && form[0] && (form[0].is_admin === true)) {
        return (
            <>{props.children}</>
        )
    } else {
        return null;
    }
};

export default AdminLi;
