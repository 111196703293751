import React from "react";

export default function AttShop({ model, title }) {
  return (
    <>
    <table className="table table-responsive fixa table-borderless mt-3">
      <thead>
        <tr>
          <th colSpan="2"> {title ? title: 'Att fixa' } </th>
        </tr>
      </thead>
      <tbody>
        {model.form.fix?.list.map((f, i) => {
          return (
            <tr className="row" key={i}>
              <td className="col-6">
                {f.name} {f.product ? " - " + f.product.name : ""}
              </td>
              <td style={{ paddingRight: "1rem" }} className="col-6">
                {f.price} kr
              </td>
            </tr>
          );
        })}

        {model.form.screen_cover ? (
          <tr key="kr100_sc" className="row">
            <td className="col-6">Screen Cover</td>
            <td style={{ paddingRight: "1rem" }} className="col-6">
              100 kr
            </td>
          </tr>
        ) : null}

        {model.form.express ? (
          <tr key="kr100_exp" className="row">
            <td className="col-6">Express</td>
            <td style={{ paddingRight: "1rem" }} className="col-6">
              100 kr
            </td>
          </tr>
        ) : null}
        {model.form.inspection ? (
          <tr key="kr100_insp" className="row">
            <td className="col-6">Undersökning</td>
            <td style={{ paddingRight: "1rem" }} className="col-6">
              100 kr
            </td>
          </tr>
        ) : null}
        {model.form.extras &&
          model.form.extras.map((extra) => (
            <tr key="extra_price" className="row">
              <td className="col-6">{extra.name}</td>
              <td style={{ paddingRight: "1rem" }} className="col-6">
                {extra.price} kr
              </td>
            </tr>
          ))}
          
        <tr
          key="summa"
          className="row"
        >
          <td className="col-6">
            <strong>Summa</strong>
          </td>
          <td style={{ paddingRight: "1rem" }} className="col-6">
            {model.form.discountedPrice} kr
          </td>
        </tr>

        <tr>
          <td colSpan={2}> <hr /> </td>
        </tr>

        <tr
          key="infolabel"
        >
          <td colSpan={2} style={{textAlign: 'left'}}>
            <strong>Info:</strong>
          </td>
        </tr>

        <tr
          key="infotext"
        >
          <td colSpan={2}  style={{ textAlign: "left" }}>
            {model.form.info}
          </td>
        </tr>
        <tr>
          <td colSpan={2}> <hr /> </td>
        </tr>

        <tr
          key="resp"
        >
          <td colSpan={2}  style={{ textAlign: "left" }}>
            <strong style={{ textAlign: "left" }}>Jag acceptera på att sälja produkten och tar fullt ansvar!</strong>
          </td>
        </tr>

    

      </tbody>
    </table>


    <div className="row pb-5 pt-3 px-1">
      <div style={{ textAlign: "left" }} className="col-3">Signatur:</div>
      <div style={{ paddingRight: "1rem" }} className="col-9">
        <hr />
      </div>
    </div>
    </>
  );
}
