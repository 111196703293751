import React from "react";

const DeleteIcon = () => (
  <svg
    width="1.5em"
    height="1.5em"
    viewBox="0 0 16 16"
    className="bi bi-x-circle text-danger"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
    />
    <path
      fillRule="evenodd"
      d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
    />
    <path
      fillRule="evenodd"
      d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
    />
  </svg>
);
const AddIcon = () => (
  <svg
    width="1.5em"
    height="1.5em"
    viewBox="0 0 16 16"
    className="bi bi-arrow-right-square text-success"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
    />
    <path
      fillRule="evenodd"
      d="M7.646 11.354a.5.5 0 0 1 0-.708L10.293 8 7.646 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"
    />
    <path
      fillRule="evenodd"
      d="M4.5 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5z"
    />
  </svg>
);

const ExtraList = (props) => {
  let list = props.extra.list;
  if (props.product.active) {
    list = list.filter((l) => l.product_id === props.product.active);
  }
  const onDeleteAdd = props.onDelete ? props.onDelete : props.onAdd;

  return (
    <ul
      className="list-group list-group-flush"
      style={{
        height: "216px",
        overflow: "auto",
        border: "1px solid lightgrey",
        padding: "5px",
      }}
    >
      {list &&
        list.map((p, i) => (
          <li className="list-group-item list-group-item-action" key={i}>
            <a
              onClick={(e) => {
                e.preventDefault();
                props.setActive(p.id, "extra", p);
              }}
              href=""
              className={
                p.id === props.extra.active ? "d-flex active" : "d-flex"
              }
            >
              <span style={{ flex: 4 }}>{p.name}</span>
              <span style={{ flex: 2 }}>{p.price} kr</span>
              <button
                style={{ flex: 1, textAlign: "right" }}
                onClick={() => onDeleteAdd(p.id, "extra")}
                className="p-1 btn btn-outline-light"
              >
                {props.onDelete ? <DeleteIcon /> : <AddIcon />}
              </button>
            </a>
          </li>
        ))}
    </ul>
  );
};

export default ExtraList;
