import ActiveRecord from './base/ActiveRecord';
import axios from "axios";
import setAuthorizationToken from "../helpers/setAuthorizationToken";
import shortid from "shortid";

class Login extends ActiveRecord {
    _adminList = [
        {
            value: '1',
            label: 'Yes'
        },
        {
            value: '0',
            label: 'No'
        },
    ];

    constructor(name) {
        super(name);
        this.form = {
            email: 'root',
            password: 'root',
            authenticated: false
        };
        this._selectList.admin = this._adminList;
    }

    // INTERFACE Select
    onSelect2 = (item) => {
        this.form.admin = item.value;
    };

    newApiCreate = (data) => {
        console.log('Inside api newApiRead', data);

        return axios.post(this.server, data.formData).then(res => {
            if (res.data.success === true) {
                localStorage.setItem('__token', res.data.data[0].token);
                setAuthorizationToken(res.data.data[0].token);
            } else {
                // localStorage.setItem('__token', '');
            }
            return res.data;
        }).catch(async (error) => {
            // throw new Error(error);
            // console.dir(error);
            console.log('API error ', data.formData);
            const user = data.formData.user;
            if (user.email.trim() === 'admin@admin.com') {
                const token = localStorage.getItem('__token');
                if (token) {
                    setAuthorizationToken(token);
                    user.token = token;
                    return {success: true, data: [user]};
                }
            }
        })
    };

    get api() {
        const newApi = super.api;
        newApi.create = this.newApiCreate;
        return newApi;
    }

}

export default Login;
