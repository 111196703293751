import React from "react";

const Make = (props) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="input-group">
          <input
            value={props.make.form.name}
            onKeyDown={(e) => props.onKeyDown(e, "make")}
            onChange={(e) => props.onChange(e, "make")}
            id="name"
            type="text"
            className="form-control"
            placeholder="Lägga till märke"
          />
          <div className="input-group-append">
            <button
              onClick={() => props.onCreate("make")}
              type="submit"
              className="btn btn-primary p-2"
            >
              {props.make.buttonLabel}
            </button>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="list-group mt-3"></div>
      </div>
    </div>
  );
};

export default Make;
