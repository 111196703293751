import ActiveRecord from './base/ActiveRecord';

class Extra extends ActiveRecord {

    constructor(name) {
        super(name);
        this.form = {
            name: '',
            product_id: ''
        };
    }
}

export default Extra;
