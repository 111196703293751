import React from "react";


class Alert extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {errors} = this.props;
        console.log('Errors ', errors, errors === null)
        if (errors === false || errors === null || errors === undefined) return null;
        return (
            <div style={{padding: '15px'}} className={"alert alert-dismissible fade show " + errors && errors.type ? errors.type : null} role="alert">
                <h4 className="alert-heading">{errors? errors.header : null}
                <button onClick={()=>this.props.clearErrors()} style={{float: 'right'}} type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </h4>
                {
                    errors && errors.errors && Array.isArray(errors.errors) && Object.keys(errors.errors[0]).map((k, i) =>
                        <p className="medium" key={i}><strong>{k} : </strong>{errors.errors[0][k].message}</p>)
                }
                <hr/>
                <p className="mb-0">{errors?.done ? "Done !" : "Please correct the errors above and try again."}</p>
            </div>
        )
    }
}

export default Alert;

