import React from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import models from "../../store/models";


class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: undefined,
            customers_count: 0
        }
    }

    componentDidMount() {
        this.updateState(this.props);
    }

    updateState(props) {
        const {logins, orders} = props;
        if (logins && logins.form && logins.form[0]) {
            const {email} = logins.form[0];
            const customers_count = Number(orders.list?.length);
            this.setState({email, customers_count});
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.updateState(nextProps);
    }

    logout = () => {
        // localStorage.setItem('__token', '');
        this.props.setAction({email: '', id: ''});
        this.props.history.push('/');
    };

    render() {
        const {email} = this.state;
        if(email === undefined || email === '' || email.length===0) return <div>...</div>;
        return (
            <div>
                {
                    this.state.email ?
                        <>
                            <strong className="text-warning">
                                {this.state.email.split('@')[0]}
                            </strong>
                            <strong className="ml-3 text-success text-capitalize">
                                <button onClick={this.logout} className="btn btn-outline-success btn-sm pr-3">
                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-box-arrow-right mr-2"
                                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M11.646 11.354a.5.5 0 0 1 0-.708L14.293 8l-2.647-2.646a.5.5 0 0 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0z"/>
                                        <path fillRule="evenodd"
                                              d="M4.5 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5z"/>
                                        <path fillRule="evenodd"
                                              d="M2 13.5A1.5 1.5 0 0 1 .5 12V4A1.5 1.5 0 0 1 2 2.5h7A1.5 1.5 0 0 1 10.5 4v1.5a.5.5 0 0 1-1 0V4a.5.5 0 0 0-.5-.5H2a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-1.5a.5.5 0 0 1 1 0V12A1.5 1.5 0 0 1 9 13.5H2z"/>
                                    </svg>
                                    LOGOUT
                                </button>
                            </strong>
                            <br/>
                            <strong className="text-white-50">
                                Kund: {this.state.customers_count}
                            </strong>
                        </>
                        : null
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    logins: state.logins,
    orders: state.orders
});

const mapActionsToProps = {
    setAction: models.logins.actions.set
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(User));
