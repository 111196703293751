class Make {
    id = '';
    name = '';
    list = [];
    loaded = false;

    constructor(makes = false) {
        this.setList(makes.list);
    }

    load(model) {
        this.id = model.form.make?.id;
        this.name = model.form.make?.name;
    }

    setList(list) {
        console.log('Make setList', list)
        if (list && list.length > 0 && this.loaded === false) {
            this.list = list;
            this.loaded = true;
        }
    }

    get options() {
        return this.list.map(make => ({value: make.id, label: make.name}));
    }

    get selectedOption() {
        return {value: this.id, label: this.name};
    }

    set selectedOption(option) {
        this.id = option.value;
        this.name = option.label;
    }

    get form() {
        return {id: this.id, name: this.name};
    }
    clear() {
        this.id = '';
        this.name = '';
    }
}

export default Make;
