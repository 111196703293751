import ActiveRecord from './base/ActiveRecord';

class Search extends ActiveRecord {

    constructor(name) {
        super(name);
        this.form = {
            service_number: '',
            name: '',
            telephone: '',
            show_order_list: false
        };
    }

    // ACTIONS
    get actions() {
        return {
            setName: (data) => ({type: 'set_name', payload: {data}}),

            read: (data) => ({type: this.types.read, payload: data}),
            read_success: (data) => ({type: this.types.read_success, payload: {data}}),
            read_fail: (data) => ({type: this.types.read_fail, payload: {data}}),


        }
    }

    // REDUCERS
    get reducers() {
        const initState = {
            list: [],
            form: {},
            actions: {
                type: null, ok: false
            }
        };

        const reducer = (state = initState, action = {}) => {
            let data, form, newState;
            switch (action.type) {
                case 'set_name':
                    data = action.payload.data;
                    return {
                        ...state, ...{form: {...data}}
                    };
                    break;
                default:
                    return initState;
            }
        };
        return reducer;
    }
}

export default Search;
