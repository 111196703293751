import state from "../receipt/state";
import { maxBy, padStart, invert } from "lodash";
import moment from "moment";
import { statuses } from "../../common/constants";

const COLORS = {
  red: "#ed969e",
  green: "#8fd19e",
  blue: "#81b4fe",
  orange: "#febc85",
  yellow: "#ffe9a6",
};

class Order {
  isNewRecord = true;
  form = state().form;
  price = 0;
  sum = 0;
  list = [];
  loaded = false;
  listLoaded = false;
  errors = null;
  settings = {};

  constructor(form = null) {
    if (form) {
      this.isNewRecord = false;
      this.form = form;
    }
  }

  setList(list) {
    if (list && list.length > 0) {
      this.list = list;
      this.listLoaded = true;
    }
  }

  set deal(deal) {
    this.form.deal = deal;
  }

  addNew() {
    this.form = state().form;
    this.isNewRecord = true;
    this.nextServiceNumber();
  }

  nextServiceNumber() {
    const LENGTH = 6;
    if (this.list.length === 0) {
      return (this.form.service_number = this.settings.token
        .service_number_prefix
        ? this.settings.token.service_number_prefix + "000001"
        : "A000001");
    }
    const maxServiceNr = this.findMaxServiceNumber(); // maxBy(this.list, "service_number");
    let num = maxServiceNr ? maxServiceNr + 1 : 1;
    return (this.form.service_number =
      this.settings.token.service_number_prefix + padStart(num, LENGTH, "0"));
  }

  findMaxServiceNumber() {
    let max = 0;
    window.list = this.list;
    for (let i = 0; i < this.list.length; i++) {
      let numServiceNumber = this.findNumbersOnly(this.list[i].service_number);
      if (max < numServiceNumber) {
        max = numServiceNumber;
      }
    }
    console.log("max:" + max);
    return max;
  }

  findNumbersOnly(serviceNumber) {
    let nums = "";
    for (let i = 0; i < serviceNumber.length; i++) {
      if (isNaN(serviceNumber[i])) {
      } else {
        nums = nums + (Number(serviceNumber[i]) + "");
      }
    }
    return Number(nums);
  }

  toMongoDate(dated, time) {
    const date = new Date(dated);
    const [hours, minutes] = time.split(":");
    if (hours && minutes) {
      date.setHours(Number(hours));
      date.setMinutes(Number(minutes));
      return date.toISOString();
    }
    return time;
  }

  create(make, product) {
    const order = { ...this.form };
    order.date = order.time = this.toMongoDate(this.form.date, this.form.time);
    order.date_picked = order.time_picked = this.toMongoDate(
      this.form.date_picked,
      this.form.time_picked
    );
    order.make = make.form;
    order.product = product.form;
    return order;
  }

  fromMongoDate(dated, time) {
    this.form[dated] = moment(this.form[dated]).format("YYYY-MM-DD");
    this.form[time] = moment(this.form[time]).format("HH:mm");
  }

  load(id) {
    const order = this.findById(id);
    if (order) {
      this.isNewRecord = false;
      this.form = order;
      this.fromMongoDate("date", "time");
      this.fromMongoDate("date_picked", "time_picked");
      this.loaded = true;
    }
  }

  findById(id) {
    return this.list.find((item) => item.id === id);
  }

  addFix(item) {
    // Check if item already exists then increase qty
    console.info("addFix item:", item);
    const foundIndex = this.form.fix.list.findIndex(
      (element) => element._id == item._id
    );
    if (foundIndex > -1) {
      console.log("foundIndex:", foundIndex);
      const foundItem = this.form.fix.list[foundIndex];
      foundItem.qty = foundItem.qty + 1;
      this.form.fix.list[foundIndex] = { ...foundItem };
    } else {
      const newItem = { ...item };
      newItem.qty = 1;
      this.form.fix.list.push(newItem);
    }
    // this.form.price += Number(item.price);
    this.calculateDiscountedPrice();
  }

  removeFix(item) {
    // Check if qty is 1 then remove it from list else decrease qty by 1

    const foundIndex = this.form.fix.list.findIndex(
      (element) => element._id == item._id
    );
    if (foundIndex == -1) {
      console.log("Item not found");
      return false;
    }

    const foundItem = this.form.fix.list[foundIndex];
    if (foundItem.qty > 1) {
      foundItem.qty = foundItem.qty - 1;
      this.form.fix.list[foundIndex] = foundItem;
    } else {
      // Remove the last item
      this.form.fix.list = this.form.fix.list.filter(
        (fix) => fix.id !== item.id
      );
    }

    this.calculateDiscountedPrice(); // this.form.price -= Number(item.price);
  }

  calculateDiscount(discountedPrice) {
    this.calculateDiscountedPrice(false);
    this.form.discount = (
      (100 * (this.form.price - discountedPrice)) /
      this.form.price
    ).toFixed(2);
  }

  calculateDiscountedPrice(discountedPrice = true) {
    this.sum = this.form.fix.list.reduce(
      (total, item) => total + Number(item.price) * Number(item.qty),
      0
    );

    if (this.form.screen_cover === true) {
      this.sum = this.sum + 100;
    }

    if (this.form.express === true) {
      this.sum = this.sum + 100;
    }

    if (this.form.inspection === true) {
      this.sum = this.sum + 100;
    }

    if (this.form.extras && Array.isArray(this.form.extras)) {
      this.form.extras.forEach((extra) => {
        if (extra.selected) {
          this.sum = this.sum + Number(extra.price);
        }
      });
    }

    this.form.price = this.sum;
    if (discountedPrice)
      this.form.discountedPrice = (
        this.sum -
        (this.sum * Number(this.form.discount)) / 100
      ).toFixed(2);
  }

  existExtra(extra) {
    let i = -1;
    if (this.form.extras && Array.isArray(this.form.extras)) {
      i = this.form.extras.findIndex((e) => e.id == extra.id);
    }

    if (i !== -1) {
      // found
      console.log("exists", i, extra);
      return true;
    }
    return false;
  }

  addExtra(extra) {
    let i = -1;
    if (this.form.extras && Array.isArray(this.form.extras)) {
      i = this.form.extras.findIndex((e) => e.id == extra.id);
    }
    if (i !== -1) {
      // found
      console.log("found " + i);
      if (extra.selected) {
        console.log("selected " + i);
        this.form.extras[i] = extra;
      } else {
        console.log("not selected, removed " + i);
        this.form.extras.splice(i, 1);
      }
    } else {
      console.log("not found, " + extra.selected);
      if (extra.selected) {
        if (this.form.extras && Array.isArray(this.form.extras)) {
          console.log("Extras not exist on form", this.form);
        } else {
          this.form["extras"] = [];
        }
        this.form.extras.push(extra);
      }
    }
  }

  toggleCheckBox(field) {
    if (field === "customer_picked" && this.form[field] === false) {
      this.form.date_picked = new Date().format();
      this.form.time_picked = new Date().timeFormat();
    }
    this.form[field] = !this.form[field];
    this.calculateDiscountedPrice();
  }

  get paymentMethod() {
    return { value: this.form.payment_method, label: this.form.payment_method };
  }

  get paymentMethods() {
    const options = [
      { value: "Kort", label: "Kort" },
      { value: "Kontant", label: "Kontant" },
      { value: "Swish", label: "Swish" },
      { value: "Klarna", label: "Klarna" },
      { value: "Faktura", label: "Faktura" },
    ];
    return options;
  }

  get status() {
    const iStatuses = invert(statuses);
    return {
      value: Array.isArray(this.form.status)
        ? this.form.status[0]
        : this.form.status,
      label: iStatuses[this.form.status],
    };
  }

  get statusOptions() {
    const iStatuses = invert(statuses);
    const options = Object.keys(statuses).map((key) => ({
      value: statuses[key],
      label: iStatuses[statuses[key]],
    }));
    return options;
  }

  get discount_amount() {
    if (!this.form.discount) {
      return 0;
    }
    return ((this.form.discount / 100) * this.form.price).toFixed(2);
  }

  moms_percent() {
    return this.form.deal === "sell" ? 20 : 25;
  }

  get moms_amount() {
    return (
      (this.moms_percent() / 100) *
      Number(this.form.discountedPrice)
    ).toFixed(2);
  }

  get exclusive_moms() {
    const moms = 20 / 100;
    return Number(
      this.form.discountedPrice - this.form.discountedPrice * moms
    ).toFixed(2);
  }

  setSettings(settings) {
    this.settings = settings;
  }

  refreshDateTimePicked(datetime) {
    if (datetime === "date_picked") {
      this.form.date_picked = new Date().format();
    }
    if (datetime === "time_picked") {
      this.form.time_picked = new Date().timeFormat();
    }
  }

  static style(row) {
    const { date_picked, status } = row;
    const now = moment(new Date());
    const end = moment(date_picked);
    const duration = moment.duration(end.diff(now));
    const minutes_remaining = Math.floor(duration.asMinutes());
    let color = { backgroundColor: "white" };

    if (minutes_remaining < 30) {
      color = { backgroundColor: COLORS.red };
    }
    if (minutes_remaining < 45) {
      color = { backgroundColor: COLORS.yellow };
    }

    switch (status) {
      case statuses.FINISHED:
        color = { backgroundColor: "white" };
        break;
      case statuses.WAIT_CUSTOMER:
        color = { backgroundColor: COLORS.orange };
        break;
      case statuses.WAIT_PARTS:
        color = { backgroundColor: COLORS.blue };
        break;
    }

    return color;
  }

  set product(seletedOption) {
    this.form.product.name = seletedOption.label;
    this.form.product.id = seletedOption.value;
  }
}

export default Order;
